import {
  convertISOStringToFormattedDateTime,
  convertISOStringToFormattedDateToTimeOnly,
} from "../../../../utils/time";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import { hasRole } from "../../../../utils/auth";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useHistory } from "react-router-dom";

export default function EventInfo(props) {
  const { user, event, loading, isEditingDetails, setEditingDetails } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  return (
    <section aria-labelledby="event-information-title">
      <div
        className={`bg-white shadow sm:rounded-lg ${
          loading && "animate-pulse"
        }`}
      >
        <div className="flex">
          <div className="px-4 py-5 sm:px-6">
            <h2
              id="applicant-information"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Event Information - {event.name}
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {event.location}
            </p>
          </div>
          <div>
            <div className="flex-row">
              {hasRole(user, ["Tenant Admin", "User"]) && (
                <button
                  type="button"
                  onClick={() => {
                    setEditingDetails(true);
                  }}
                  className="mt-4 -mr-2 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gemstoneblue-500"
                >
                  <PencilAltIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Edit the event details</span>
                </button>
              )}
            </div>
            <div className="flex-row">
              {hasRole(user, ["Tenant Admin", "User"]) && (
                <button
                  type="button"
                  className="mt-4 -mr-2 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gemstoneblue-500"
                  onClick={() => history.push(`/events/review/${event.uuid}`)}
                >
                  <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Complete the event</span>
                </button>
              )}
            </div>
            <div className="flex-row">
              {hasRole(user, ["Tenant Admin", "User"]) && (
                <button
                  type="button"
                  onClick={() => {
                    toggleModal()
                  }}
                  className="mt-4 -mr-2 h-8 w-8 bg-white rounded-full flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gemstoneblue-500"
                >
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Cancel the event</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900">{event.address}</dd>
              {event.address2 !== null && (
                <dd className="mt-1 text-sm text-gray-900">{event.address2}</dd>
              )}
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {convertISOStringToFormattedDateTime(event.date)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Call Time</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {convertISOStringToFormattedDateToTimeOnly(event.call_datetime)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Facilities</dt>
              <dd className="mt-1 text-sm text-gray-900">{event.facilities}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Parking</dt>
              <dd className="mt-1 text-sm text-gray-900">{event.parking}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Credentials</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {event.credentials}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Transmission
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {convertISOStringToFormattedDateToTimeOnly(
                  event.transmission_datetime
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Transmission Company
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {event.transmission_company}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Channel Assignment
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {event.channel_assignment}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Budget</dt>
              <dd className="mt-1 text-sm text-gray-900">{event.budget}</dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Notes</dt>
              <dd className="mt-1 text-sm text-gray-900">{event.notes}</dd>
            </div>
          </dl>
        </div>
      </div>
      <ConfirmDialog
        open={modalOpen}
        setOpen={setModalOpen}
        item={event}
        onClick={() => {}}
        text={'Are you sure you want to cancel this event? All confirmed staff will be notified.'}
        primaryText={'Cancel Active Event'}
        buttonText={'Cancel Event'}
      />
    </section>
  );
}
