export default function Rate(props) {
  const { fixed, hourly } = props;

  return (
    <span>
      {hourly && `${hourly}/Hour`}
      {fixed && `${fixed}/Day`}
    </span>
  );
}
