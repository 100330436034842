import { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "../../utils/helpers";
import OfferModal from "../Offer";
import { TrashIcon } from "@heroicons/react/solid";
import { getUserDetails } from "../../containers/Login/slice";
import { hasRole } from "../../utils/auth";

export type User = {
  email: string;
  image?: string;
  first_name: string;
  last_name: string;
  phone: string;
  status: string;
  uuid: string;
};

export type EventRole = {
  role_name: string;
  uuid: string;
};

export type UserEventRole = {
  user: User;
  event_role: EventRole;
  status: string;
  agreement_id?: string;
  user_event_role_id?: string;
};

export default function AvatarTable(props: {
  removeItemFrom: any;
  people: UserEventRole[];
  setter: any;
  roles?: any;
  event?: any;
  offerSubmitHandler?: Function;
  loading?: boolean;
  cancelOffer?: Function;
  removeItemNotification?: Function;
}) {
  const {
    people,
    removeItemFrom,
    setter,
    event,
    offerSubmitHandler,
    loading,
    cancelOffer,
    removeItemNotification,
  } = props;
  const [open, setOpen] = useState(false);
  const [offerPerson, setPerson] = useState({
    user: {
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      status: null,
      uuid: null,
    },
    event_role: {
      role_name: null,
      uuid: null,
    },
    status: null,
  });
  const [offerRate, setRate] = useState();
  const [offerType, setOfferType] = useState("fixed");
  const [terms, setTerms] = useState("");
  const popModal = (person, rate) => {
    setOpen(true);
    setPerson(person);
    setRate(rate);
  };
  const user = useSelector(getUserDetails);

  const OfferNotStarted = (prop) => {
    const { person } = prop;
    return (
      <span
        className={classNames(
          person?.user.status
            ? "bg-green-100 text-green-800"
            : "bg-gray-100 text-gray-800",
          "px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
        )}
      >
        $0
        {/* {person?.default_role_rate
          ? `$${person.default_role_rate}`
          : person.default_role_rate} */}
      </span>
    );
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role/Position
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Cell Phone
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    {hasRole(user, ['Tenant Admin']) && <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Accepted Rate
                    </th>}
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {people?.map((person: UserEventRole, index, people) => (
                    <tr key={person?.user.email}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={person?.user.image}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {person?.user.first_name +
                                " " +
                                person?.user.last_name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {person?.user.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {person?.event_role.role_name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {person?.user.phone}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={classNames(
                            person?.status === "ready for offer"
                              ? "bg-green-100 text-green-800"
                              : "bg-yellow-100 text-yellow-800",
                            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                          )}
                        >
                          {person?.status}
                        </span>
                      </td>
                      { hasRole(user, ['Tenant Admin']) && <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          <OfferNotStarted person={person} />
                        </div>
                      </td> }
                      { hasRole(user, ['Tenant Admin']) && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="ml-4 mt-2 flex-shrink-0">
                          <button
                            onClick={() => {
                              person?.status === "offer sent"
                                ? cancelOffer(person)
                                : popModal(person, 100);
                            }}
                            type="button"
                            className={`relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md ${
                              person?.status === "offer sent"
                                ? "text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`"
                                : "text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                            }`}
                          >
                            {person?.status === "offer sent"
                              ? "Send cancellation"
                              : "Offer"}
                            <a
                              href=""
                              className="text-gemstoneblue-600 hover:text-gemstoneblue-900"
                            ></a>
                          </button>
                          <button>
                            <TrashIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                              onClick={(e) => {
                                removeItemFrom(
                                  "event_staff",
                                  person,
                                  people,
                                  setter,
                                  event,
                                  removeItemNotification
                                );
                              }}
                            />
                          </button>
                        </div>
                      </td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <OfferModal
        open={open}
        setOpen={setOpen}
        person={offerPerson}
        defaultrate={offerRate}
        setOfferType={setOfferType}
        offerType={offerType}
        event={event}
        submitOffer={offerSubmitHandler}
        loading={loading}
        terms={terms}
        setTerms={setTerms}
      />
    </>
  );
}
