import React from 'react';
import TopNav from '../../containers/TopNav';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ExclamationIcon } from '@heroicons/react/outline';

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.error(error, errorInfo);
    }

    clearAndClose = (history) => {
      this.setState({ hasError: false });
      setTimeout(() => {
        history.push('/login')
      }, 3000);
    }
  
    render() {
      const { hasError } = this.state;
      const history = createBrowserHistory();
      if (this.state && hasError) {
        const store = configureStore({
          reducer: {
            root: (state = {}, action) => {
              return state;
            },
            user: (state = {
              user: {
                isLoggedIn: false
              }
            }, action) => {
              return state;
            }
          }
        })
        // You can render any custom fallback UI
        return (
          <Provider store={store}>
            <Router history={history}>
              <TopNav>
                <div className="m-8 full-w">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-24 w-24 rounded-full bg-gemstoneplaybook-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-24 w-24 text-gemstoneblue-600" aria-hidden="true" />
                  </div>
                  <h1>Something went wrong.</h1>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gemstoneblue text-base font-medium text-white hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500 sm:text-sm"
                    onClick={() => {this.clearAndClose(history)}}>
                    Back to Login
                  </button>
              </div>
              </TopNav>
            </Router>
          </Provider>
        );
      }
  
      return this?.props?.children; 
    }
  }

export default ErrorBoundary;
