const Companyfield = ({setCompanyName, companyName, setIsCompany, error}) => (
    <div>
      <label
        htmlFor="company-name"
        className="block text-sm font-medium text-gray-700"
      >
        Business Name
      </label>
      <div className="mt-1 mb-8">
        <input
          id="company-name"
          name="company-name"
          value={companyName}
          type="company-name"
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
          autoComplete="company-name"
          required
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
        />
      <div className="text-sm">
          <button type="button" onClick={(e) => {setIsCompany(false)}}>
            <a href="javascript: void()" className="font-medium text-gemstoneblue-600 hover:text-gemstoneblue-500">
              Not a business?
            </a>
          </button>
        </div>
      </div>
    <div>
      {error !== "" && (
        <div className="block text-sm font-medium text-red-700">
          {error}
        </div>
      )}
    </div>
  </div>
  )

export default Companyfield;
