import { convertISOStringToFormattedDateTime } from "../../../../utils/time";

export default function invoice(props) {
    const { invoice, loading } = props;
    if (!invoice) return <div>Loading</div>
    const { user } = invoice;
    return (
        <section aria-labelledby="invoice-information-title">
          <div className={`bg-white shadow sm:rounded-lg ${loading && 'animate-pulse'}`}>
            <div className="flex">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Invoice Information - {invoice.uuid ?? 'Invoice id not provided'}
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {invoice.created_at ? convertISOStringToFormattedDateTime(invoice.created_at) : 'Invoice date not provided'}
                </p>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Rate Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {invoice.daily ? 'Daily' : null}
                    {invoice.fixed_price ? 'Fixed' : null}
                    {invoice.hourly ? 'Hourly': null}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Total Hours</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {invoice.total_hours}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Total Amount Paid
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    { invoice.total_amount}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Invoice Payee
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {user.first_name} { user.last_name}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Payee Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {user.email ?? 'Email not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Payee Location</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {user.city}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Payee Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {user.phone}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </section>
    )
}