/* This example requires Tailwind CSS v2.0+ */
export default function CardWithAction(props) {
  const { heading, content, button, handler, allowedAction } = props;
  return (
    <div>
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 shadow rounded-t-lg overflow-hidden shadow">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {heading ?? ""}
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            {allowedAction && <button
              onClick={handler ?? ""}
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              {button ?? ""}
            </button>}
          </div>
        </div>
      </div>
      {content ?? ""}
    </div>
  );
}
