// import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from "@reduxjs/toolkit";

interface RootState {
  loading: boolean;
  message: string;
}

const initialState: RootState = {
  loading: false,
  message: "",
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    startLoading(state) {
      return {
        ...state,
        message: "Loading...",
        loading: true,
      };
    },
    stopLoading(state) {
      return {
        ...state,
        message: "Loading complete!",
        loading: false,
      };
    },

    // incrementByAmount(state, action: PayloadAction<number>) {
    //   state.value += action.payload
    // },
  },
});

export const { startLoading, stopLoading } = rootSlice.actions;
export const isLoading = (state: RootState) => state;

export default rootSlice.reducer;
