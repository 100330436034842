import { combineReducers, Reducer } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
// import logger from 'redux-logger'
import rootSlice from '../rootSlice'
import { reduxBatch } from '@manaflair/redux-batch'
import notificationSlice, { initialState } from './../containers/Notification/slice'
import { laravelApi } from '../services/laravel';
import { userApi } from '../services/user';
import { authApi } from '../services/auth';
import eventSlice from '../slices/events'
import userSlice from '../containers/Login/slice';
import errorMiddleware from './errorMiddleware';
import storage from 'redux-persist/lib/storage'; // defaults to local storage
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

declare const reducer: Reducer<{}>
export default reducer

const preloadedState = {
  ...initialState
}

const persistConfig = {
  key: 'user',
  storage
};

const persistedUserSlice = persistReducer(persistConfig, userSlice);

export const store = configureStore({
  reducer: combineReducers({
    root: rootSlice,
    event: eventSlice,
    user: persistedUserSlice,
    [laravelApi.reducerPath]: laravelApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    notifications: notificationSlice
  }), // <---- combine slices/reducers here
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
  .concat(errorMiddleware)
  .concat(laravelApi.middleware)
  .concat(userApi.middleware)
  .concat(authApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
  enhancers: [reduxBatch],
})

export const persistor = persistStore(store);

export type StoreState = ReturnType<typeof store.getState>;

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were composed together