/* This example requires Tailwind CSS v2.0+ */
const people = [
    {
      id: 1,
      uuid: 'd7e8a605-6c7b-4562-b51b-e78935e77b0f',
      first_name: 'George',
      last_name: 'Birnbaum',
      phone: '904-123-2134',
      title: 'Production Manager',
      department: 'Operations',
      role_name: 'Production Manager',
      email: 'gb@gemstonemediainc.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      id: 2,
      first_name: 'Adam',
      last_name: 'Struthers',
      uuid: '56a8a8ac-c21c-4606-a4b2-5468bec68fc9',
      phone: '904-123-2134',
      title: 'Play by Play',
      department: 'Optimization',
      role_name: 'Play by Play',
      email: 'test@gemstoneplaybook.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      id: 0,
      first_name: 'Philip',
      last_name: 'Green',
      phone: '904-123-2134',
      title: 'Color Announcer',
      department: 'Optimization',
      role_name: 'Color Announcer',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      id: 3,
      first_name: 'Justin',
      last_name: 'Bieber',
      phone: '904-123-2134',
      title: 'Cameraman',
      department: 'Optimization',
      role_name: 'Cameraman',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      id: 4,
      first_name: 'Dwayne',
      last_name: 'Johnson',
      phone: '904-123-2134',
      title: 'Cameraman',
      department: 'Optimization',
      role_name: 'Cameraman',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    {
      id: 5,
      first_name: 'Jane',
      last_name: 'Cooper',
      phone: '904-123-2134',
      title: 'Cable runner',
      department: 'Optimization',
      role_name: 'Cable runner',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    // More people...
  ]

  export default people;