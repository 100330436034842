import { useState, useEffect } from "react";
import EventInfo from "../Details/EventInfo";
import ReviewEventStaff from "../../../components/ReviewEventStaff";
import { instance } from "../../../utils/axios";
import { useHistory, useParams } from "react-router-dom";
import { DetailsParams } from "../Details";
import { useDispatch } from "react-redux";
import { showFailure, showSuccess } from "../../Notification/slice";
import CreateTeam from "./CreateTeam";

const Review = () => {
  const axios = instance;
  const [event, setEvent] = useState({
    location: null,
    name: null,
    address: null,
    address2: null,
    date: null,
    call_datetime: null,
    facilities: null,
    parking: null,
    credentials: null,
    ready_datetime: null,
    transmission_datetime: null,
    transmission_company: null,
    uuid: null,
    notes: null,
    budget: null,
    channel_assignment: null,
    status: null,
  });
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createTeam, setTeamCreate] = useState(false);
  const [teamName, setTeamName] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  let { uuid } = useParams<DetailsParams>();
  useEffect(() => {
    setLoading(true);
    const URL = `/api/events/${uuid}/full-details`;
    const eventRequest = axios.get(URL);
    eventRequest.then((eventRequestResponse) => {
      setEvent(eventRequestResponse.data);
      setPeople(eventRequestResponse.data.roles);
      setLoading(false);
    });
  }, []);

  const toggleContractorPresent = (person) => {
    // Toggled property only used in UI layer. Doesn't exist by default
    if (person.toggled) {
      person.toggled = false;
    } else {
      person.toggled = true;
    }
    const personIndex = people.findIndex(
      (item) => item.user.uuid === person.uuid
    );
    const peopleCopy = people;
    people[personIndex] = person;
    setPeople([...peopleCopy]);
  };

  const handleRating = (val, person) => {
    const personIndex = people.findIndex(
      (item) => item.user.uuid === person.user.uuid
    );
    const peopleCopy = people;
    peopleCopy[personIndex].rating = val;
    setPeople([...peopleCopy]);
  };

  const onToggleTeam = () => {
    setTeamCreate(!createTeam);
  };

  const onChangeName = (e) => {
    setTeamName(e.target.value);
  };

  const submitReviewHandler = async () => {
    const URL = `/api/event-review`;
    setLoading(true);
    let reviews = [];
    // Iterate over the people in the ReviewTable and generate a
    // review object for them.
    people.forEach((person) => {
      const transformToReviewModel = {
        tenant_id: person.user.tenant_id,
        user_id: person.user.uuid,
        role_id: person.event_role.uuid,
        event_id: event.uuid,
        present: person?.toggled ? "TRUE" : "FALSE",
        rating: person?.rating ? person.rating : 3,
      };
      reviews.push(transformToReviewModel);
    });
    const eventRequest = await axios.post(URL, {
      reviews: reviews,
    });
    if (eventRequest.status === 200) {
      const eventCopy = event;
      eventCopy.status = "Completed";
      await axios.put(`/api/events/${event.uuid}`, {
        ...eventCopy,
      });

      if (createTeam){
        await axios.post(
        `/api/teams/${event.uuid}/event-create`,
        {
          team_name: teamName,
        }
      );
      }
      dispatch(
        showSuccess({
          heading: "Success",
          message: "Completed Event.",
          status: true,
        })
      );
      setLoading(false);
      history.push("/events");
    } else {
      dispatch(
        showFailure({
          heading: "Failure",
          message: "Please try to submit the action again.",
          status: true,
        })
      );
      setLoading(false);
    }
  };
  return (
    <>
      <EventInfo
        event={event}
        loading={loading}
        isEditingDetails={false}
        setEditingDetails={() => {}}
      />
      <ReviewEventStaff
        people={people}
        event={event}
        submitReviewHandler={submitReviewHandler}
        toggleContractorPresent={toggleContractorPresent}
        loading={loading}
        teamName={teamName}
        createTeam={createTeam}
        handleRating={handleRating}
      >
        <CreateTeam
          onChange={onToggleTeam}
          enabled={createTeam}
          onChangeName={onChangeName}
        />
      </ReviewEventStaff>
    </>
  );
};

export default Review;
