// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery';
import { instance } from '../utils/axios';
import { handleNotification, showFailure, showSuccess } from '../containers/Notification/slice';
import { handleSetUserSuccess } from '../containers/Login/slice';
import LoginResponse from './types/loginresponse';

export const parseJwt = (token)  =>{
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const s = '{}()[]'
const isValid = (str) => {
  for (let i = 0; i < str.length; i++) {
    if (s.indexOf(str[i]) === -1) {
      return false;
    }
  }
  return true;
}
console.log(isValid('{}()[]'))

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  tagTypes: ['login', 'logout', 'register', 'updateUser', 'user'],
  endpoints: (builder) => ({
    loginWithEmailAndPassword: builder.mutation<LoginResponse, any>({
      query: ({ ...body }) => ({
        url: 'login',
        method: 'POST',
        body
      }),
      async onQueryStarted(uuid, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(handleNotification());
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(showSuccess({
            heading: "Success!",
            data: data,
            message: "Logged in successfully!",
            status: data
          }))
          // TODO: Remove all axios persistance once the auth info is stored in redux
          // and we are only using RTK-query
          instance.defaults.headers.common['Authorization'] = `Bearer ${data?.access_token}`
          instance.interceptors.request.use(function (config) {
            config.headers.Authorization = `Bearer ${data?.access_token}`
            return config;
          });
          localStorage.setItem('access_token', `Bearer ${data?.access_token}`)
          dispatch(handleSetUserSuccess(data));
        } catch (err) {
          // `onError` side-effect
          dispatch(showFailure({
            heading: "Failure!",
            message:
              "Could not login. Please try again.",
            status: false,
          }))
        }
      },
    }),
  })
});

export const {
  useLoginWithEmailAndPasswordMutation,
} = authApi;