import { useState} from 'react';
import { useHistory,} from 'react-router-dom';
import { useSendPasswordResetEmailByEmailMutation } from '../../services/laravel';

export default function ForgotPassword() {
    const history = useHistory();
    const [ email, setEmail ] = useState('');
    const [sendPasswordResetEmailByEmailMutation] = useSendPasswordResetEmailByEmailMutation();

    const submit = async (e) => {
        e.preventDefault();
        const result = await sendPasswordResetEmailByEmailMutation({email});
        if (result) {
          history.push('/login');
        }
    }

    return (
      <div className="min-h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">Reset your password</h2>
        </div>
        <div onSubmit={submit} className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div>
              </div>
  
              <div>
              <button
                    type="submit"
                    onClick={(e) => {submit(e)}}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                  >
                    Send me a reset email.
              </button>
                {/* <Link to="/">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                  >
                    Sign in
                  </button>
                </Link> */}
              </div>
            </form>
  
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or go back to login</span>
                </div>
              </div>
  
              <div className="mt-6 grid grid-cols-1">
                <div>
                    <button
                        onClick={(e) => {history.push('/login')}}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gemstoneblue-600 bg-white-600 hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                    >
                        Back to Login
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  