import React, { useState } from 'react';
import DateTimePicker from "react-datetime-picker";
import AvatarDropDown from '../../../components/AvatarDropDown';

const EventMeetingLayout = (props) => {
    const { onClickHandler, people } = props;
    const [meeting, setMeeting] = useState({});
    const [meetingTime, setMeetingTime] = useState("");
    const [meetingLocation, setMeetingLocation] = useState("");
    const [meetingMembers, setMeetingMembers] = useState([]);
    const [selected, setSelected] = useState(null);
  
    const handleSetMeeting = (e) => {
      setMeetingTime(e);
    };
  
    const handleRemoveMemberFromMeeting = (value) => {
      const membersCopy = [...meetingMembers];
      membersCopy.splice(membersCopy.indexOf(value), 1);
      setMeetingMembers(membersCopy);
    }
  
    const handleSetMeetingMembers = (value) => {
      const membersCopy = meetingMembers;
      if (membersCopy.indexOf(value) >= 0) {
        return;
      }
      membersCopy.push(value)
      setMeetingMembers([...membersCopy])
    }
  
    const text = 'Add staff to meeting';
    const loadingText = 'Please add a user to the event before adding them to a meeting';
    return (
      <>
        <div className="w-full">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Meeting Title
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="meeting-name"
                id="meeting-name"
                onChange={(e) => {
                  setMeeting(e.target.value);
                }}
                className="focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Production Meeting"
                aria-describedby="meeting-name"
              />
            </div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mt-4"
            >
              Meeting Location
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="meeting-location"
                id="meeting-location"
                onChange={(e) => {
                  setMeetingLocation(e.target.value);
                }}
                className="focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Production Trailer"
                aria-describedby="meeting-location"
              />
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="time"
            className="block mt-3 text-sm font-medium text-gray-700"
          >
            Meeting Time
          </label>
          <DateTimePicker
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
            onChange={(e) => handleSetMeeting(e)}
            required
            disableClock={true}
            value={meetingTime}
            name="start_datetime"
            id="start_datetime"
          />
        </div>
        <div>
          <label
            htmlFor="members"
            className="block mt-3 text-sm font-medium text-gray-700"
          >
            Meeting Attendees
          </label>
          <div className="mt-4 mb-4">
            <div className="flex -space-x-1 relative z-0 overflow-hidden">
              {meetingMembers.map((member) => {
                return (
                  <>
                  <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
                    <span className="text-xs font-medium leading-none text-white">{member?.user?.first_name[0]}{member?.user?.last_name[0]}</span>
                  </span>
                  <button
                  type="button"
                  className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gemstoneblue-400 hover:bg-gemstoneplaybook-200 hover:text-gemstoneblue-500 focus:outline-none focus:bg-gemstone-400 focus:text-white"
                >
                  <span className="sr-only">Remove member from meeting</span>
                  <svg onClick={() => {handleRemoveMemberFromMeeting(member)}} className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                  </svg>
                </button>
                </>
                )
              })}
            </div>
          </div>
          <div className="mt-1">
              <AvatarDropDown
                text={text}
                selected={selected}
                setSelected={setSelected}
                loadingText={loadingText}
                people={people}
                onChange={handleSetMeetingMembers}
              />
          </div>
        </div>
        <button
          type="button"
          className="mt-8 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500 sm:mt-8 sm:w-auto sm:text-sm"
          onClick={() => {
            onClickHandler(meeting, meetingTime, meetingLocation, meetingMembers);
          }}
        >
          Add
        </button>
      </>
    );
  };

export default EventMeetingLayout;
