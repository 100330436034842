// check react component for scope and return component if scope matches lookup
// TODO: Hydrate from data layer
export const scopeMap = {
    'Dashboard': ['User', 'Tenant Admin'],
    'Agreements': ['Tenant Admin'],
    'Events': ['Tenant Admin', 'User'],
    'Teams': ['Tenant Admin'],
    'Reviews': ['Tenant Admin'],
    'Profile': ['Tenant Admin', 'User'],
    'ManageTeam': ['Tenant Admin'],
    // used for profile link
    'Manage Team': ['Tenant Admin'],
    'Invoices': ['Tenant Admin'],
    'Settings': ['Tenant Admin', 'User'],
    'Sign out': ['Tenant Admin', 'User'], // profile
    'Your Profile': ['Tenant Admin', 'User'] // profile
    // 'AgreementUpdate': ['Contractor']
}

const withScope = (user) => (Component) => {
    const scopeMatches = (props) => {
        const intersection = (arr, arr2) => arr?.filter(element => arr2?.includes(element.name));
        const result = intersection(scopeMap[Component.name], props.roles);
        return result || [];
    };
    return (props) => {
        if (scopeMatches(user)) {
            return <Component {...props} />;
        }
        return <div>Unauthorized</div>
    };
};

export default withScope;