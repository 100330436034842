import { createSlice } from "@reduxjs/toolkit"

const eventSlice = createSlice({
    name: 'event',
    initialState: {
      eventCollection: [],
      currentEventUUID: null
    },
    reducers: {
      handleCurrentEventUUID(state, action) {
        return {
          ...state,
          currentEventUUID: action.payload
        }
      },
      handleSetCurrentEventCollection(state, action) {
        return {
          ...state,
          eventCollection: action.payload
        }
      }
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = eventSlice
  // Extract and export each action creator by name
  export const { handleCurrentEventUUID, handleSetCurrentEventCollection } = actions
  export const getEventUUID = state => state.event.currentEventUUID;
  // Export the reducer, either as a default or named export
  export default reducer