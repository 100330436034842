import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './utils/store';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './components/ErrorBoundary';
import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID}`
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </ErrorBoundary>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
