import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

const PrivateRoute = ({ children, ...rest }) => {
    let { isAuthenticated } = useAuth0();
    if (!isAuthenticated && localStorage.getItem('access_token')) {
      isAuthenticated = true;
    }
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

export default PrivateRoute;
