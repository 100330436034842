import { useState } from 'react';
import GenericModal from "../../../../components/GenericModal";
import PlainStackedList from "../../../../components/PlainStackedList";
import EventMeetingLayout from "../../Details/EventMeetingModal";
export default function EventMeeting(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    eventMeeting,
    values,
    errors,
    eventMeetingData,
    handleSubmit,
    removeItemFrom,
    setter,
    eventStaffData,
    isValid
  } = props;
  return (
    <div>
      <div
        ref={eventMeeting}
        className="shadow sm:rounded-md sm:overflow-hidden"
      >
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <div className="flex justify-content:space-between">
              <div className="flex-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Event Meetings
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Create meetings needed for the production.
                </p>
              </div>
              <span className="flex-grow-1">
                <button
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  // type="submit"
                  className="bg-white-600 border border-gray-600 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gemstoneblue hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                >
                  Add Item
                </button>
              </span>
            </div>
            {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={() => {
                  handleSubmit()
                }}
                type="submit"
                className="bg-white-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gemstoneblue hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
              >
                Next
              </button>
            </div> */}
          </div>

          <div className="">
            <PlainStackedList
              collection={eventMeetingData}
              location="event_meeting"
              removeItemFrom={removeItemFrom}
              setter={setter}
            />
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
            onClick={(e) => {
              if (props.dirty === true) {
                handleSubmit(values, isValid)
              }
            }}
            // type="submit"
            className="bg-white-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gemstoneblue hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
          >
            Submit
          </button>
        </div>
      </div>
      <GenericModal
        open={modalOpen}
        setOpen={setModalOpen}
      >
        <EventMeetingLayout
          collection={eventStaffData}
          people={eventStaffData}
          onClickHandler={setter}
        />
      </GenericModal>
    </div>
  );
}
