import { CheckCircleIcon, UserGroupIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
export default function Empty() {
    return (
        <div class="text-center">
            <CheckCircleIcon class="mx-auto h-12 w-12 text-gray-400" />
            <h3 class="mt-2 text-sm font-medium text-gray-900">Event Crews</h3>
            <p class="mt-1 text-sm text-gray-500">You haven't created your first crew yet.</p>
            <div class="mt-6">
              <Link to="/teams/create">
                <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue-600 hover:bg-gemstoneplaybook-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500">
                <UserGroupIcon class="-ml-1 mr-2 h-5 w-5" />
                Create
                </button>
              </Link>
            </div>
        </div>
    )
}