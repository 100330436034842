import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: 'user',
    initialState: {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      sub: null,
      tenant_id: null,
      uuid: null,
      address: null,
      address_line_2: null,
      city: null,
      state: null,
      postal_code: null,
      status: null,
      created_at: null,
      updated_at: null,
      access_token: null,
      expires_in: null,
      refresh_token: null,
      token_type: null,
      roles: null,
      scope: null
    },
    reducers: {
      handleSetUserSuccess(state, action) {
        return {
          ...state,
          ...action.payload.user,
          ...action.payload,
        }
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = userSlice;
  // Extract and export each action creator by name
  export const { handleSetUserSuccess } = actions;
  export const getUserDetails = state => state.user;
  // Export the reducer, either as a default or named export
  export default reducer