import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'


// Reusable middleware capturing errors
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.type === 'authApi/executeMutation/rejected') {
        action = {
          type: action.type,
          meta: action.meta,
          payload: 'Invalid email address or password.'
        }
      } else if (action.payload.status === 401) {
        localStorage.removeItem('access_token');
        window.location.href = '/login'
      }
    }

    return next(action)
}

export default rtkQueryErrorLogger;