import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ReviewList from './List';
import ReviewDetails from './Details';

export default function Reviews() {
  let { url } = useRouteMatch();
  // const history = useHistory();
  return (
    <div className="h-screen">
      <Switch>
        <Route exact path={`${url}/`} component={ReviewList} />
        <Route exact path={`${url}/detail/:uuid`} component={ReviewDetails} />
      </Switch>
    </div>
  )
};