import React from 'react';
import classNames from '../../../../utils/helpers';
import {
    CreditCardIcon,
    KeyIcon,
    UserCircleIcon,
    UserGroupIcon,
    ViewGridAddIcon,
  } from "@heroicons/react/outline";

export default function Navigation(props) {
    const { scrollTo, eventDetails, eventInfo, eventConfig, eventStaff, eventMeeting } = props;
    if (!eventDetails) {
      return (
        <div>Loading...</div>
      )
    }
    const navigation = [
        {
          name: "Event Details",
          ref: eventDetails,
          href: "#",
          icon: UserCircleIcon,
          current: true,
        },
        {
          name: "Event Information",
          ref: eventInfo,
          href: "#",
          icon: KeyIcon,
          current: false,
        },
        {
          name: "Event Configuration",
          ref: eventConfig,
          href: "#",
          icon: CreditCardIcon,
          current: false,
        },
        {
          name: "Event Staff",
          ref: eventStaff,
          href: "#",
          icon: UserGroupIcon,
          current: false,
        },
        {
          name: "Event Meetings",
          ref: eventMeeting,
          href: "#",
          icon: ViewGridAddIcon,
          current: false,
        },
      ];
    return (
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="space-y-1">
            {navigation.map((item) => (
                <a
                onClick={() => {
                    scrollTo(item.ref);
                }}
                key={item.name}
                className={classNames(
                    item.current
                    ? "cursor-pointer bg-gray-50 text-gemstoneblue-700 hover:text-gemstoneblue-700 hover:bg-white"
                    : "cursor-pointer text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                    "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
                >
                <item.icon
                    className={classNames(
                    item.current
                        ? "text-gemstoneblue-500 group-hover:text-gemstoneblue-500"
                        : "text-gray-400 group-hover:text-gray-500",
                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    )}
                    aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
                </a>
            ))}
            </nav>
      </aside>
    )
}