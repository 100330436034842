import React, { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export type People = {
  user: {
    first_name: String,
    last_name: String,
    uuid: String
  }
}
export type PeopleCollection = [
  People
]
export type AvatarDropdown = {
  onChange: Function,
  people: PeopleCollection,
  text: String,
  loadingText: String
}

export default function AvatarDropDown(props) {
  const { onChange, people, text, loadingText, selected, setSelected } = props;
  useEffect(() => {
    if (people?.length > 0) {
      setSelected(people[0])
    }
  }, [people])

  return (
    <Listbox disabled={!selected} value={selected} onChange={(e) => {onChange(e,people, selected)}}>
      <Listbox.Label className="block text-sm font-medium text-gray-700">{text}</Listbox.Label>
      <div className="mt-1 relative">
        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm">
          {!selected ? loadingText : <span className="flex items-center">
            <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
              <span className="text-xs font-medium leading-none text-white">{`${selected?.user?.first_name[0]}${selected?.user?.last_name[0]}`}</span>
            </span>
            <span className="ml-3 block truncate">{`${selected?.user?.first_name} ${selected?.user?.last_name}`}</span>
          </span>}
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <div>
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {people?.length > 0 && people.map((person) => (
              <Listbox.Option
                key={person?.user?.uuid}
                className={({ active }) =>
                  classNames(
                    active ? 'text-white bg-gemstoneblue' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-3 pr-9'
                  )
                }
                value={person}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex items-center">
                      
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                        {`${person.user.first_name} ${person.user.last_name}`}
                      </span>
                    </div>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-gemstoneblue-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </div>
    </Listbox>
  )
}