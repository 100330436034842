import { Auth0Provider } from "@auth0/auth0-react";
import { useContext, createContext } from "react";

const authContext = createContext();

const useAuth = () => {
  return useContext(authContext);
};

export const hasRole = (user, role) => {
  if (!user || !role) return false;
  const intersection = (arr, arr2) => arr?.filter(element => arr2?.includes(element.name));
  const result = intersection(user.roles, role);
  if (result && result.length > 0) return true;
  return false;
}

export const ProvideAuth = ({ children }) => {
  return (
    <Auth0Provider
      domain="gemstone-playbook.us.auth0.com"
      token
      clientId="4PO0vtD9be1tikdIWPjY2bgUL9n4IX7V"
      redirectUri={`${window.location.protocol}//${window.location.host}/authorize`}
      //audience={'http://localhost:8081/api/'}
      audience={`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/`}
      scope="events:read update:current_user_metadata create:events invoices:read"
    >
      {children}
    </Auth0Provider>
  );
};

export default useAuth;
