import { useState, useEffect } from 'react';
// import { CheckIcon, ThumbUpIcon, UserIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { CheckIcon } from '@heroicons/react/solid'
import { parseISO, format } from 'date-fns';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export type FeedItem = {
  id: number
  content: string
  target: string
  href: string
  date: string
  datetime: string
  icon: any
  iconBackground: string
}

export type Timeline = {
  timeline: any[]
  loading: boolean
}

export default function Feed(props:Timeline) {
  const { timeline, loading } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const [currentList, setCurrentList] = useState(timeline)

  useEffect(() => {
    if (timeline.length > 8) {
      setIsExpanded(false);
      setCurrentList(timeline.slice(0, 8))
    } else {
      setCurrentList(timeline)
    }
  }, [timeline])

  const toggleExpand = () => {
    if (isExpanded) {
      setCurrentList(timeline.slice(0, 8))
    } else {
      setCurrentList(timeline)
    }
    setIsExpanded(!isExpanded);
  }

  return (
    <div className={`flow-root ${loading && "animate-pulse"} min-h-1/4`}>
      <ul className={`-mb-8 min-h-1/4`}>
        {currentList && currentList.map((event, eventIdx) => (
          <li key={event.uuid}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      'bg-blue-500',
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                    )}
                  >
                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.value.split('was').length > 1 ? `${event.value.split('was')[0]} was ` : event.value}
                      <a href={event.href} className="font-medium text-gray-900">
                        {event.value.split('was')[1]}
                      </a>
                    </p>
                  </div>
                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                    { event.created_at && <time dateTime={event.created_at}>{format(parseISO(event.created_at), 'LLLL do')}</time> }
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
        <div className="w-full pb-4">
        { !isExpanded && timeline.length > 10 && <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500" onClick={() => toggleExpand()}>Show more</button>}
        { isExpanded && timeline.length > 10 && <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500" onClick={() => toggleExpand()}>Show less</button>}
      </div>
      </ul>
    </div>
  )
}
