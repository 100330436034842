import { ExclamationCircleIcon } from "@heroicons/react/solid";

export default function EventDetails(props) {
  const {
    scrollTo,
    eventDetails,
    formQuestions,
    setField,
    eventInfo,
    values,
    onChange,
    errors,
    touched,
  } = props;
  const errorStyles =
    "block w-full pr-10 border-red-500 text-red-500 placeholder-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
  return (
    <div>
      <div
        ref={eventDetails}
        className="shadow sm:rounded-md sm:overflow-hidden"
      >
        <div className="bg-white py-6 px-4 space-y-9 sm:p-9">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Event
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Setup a single event. Series coming soon.
            </p>
          </div>

          {/* <div className="mt-4 sm:mt-0 sm:col-span-2 cursor-not-allowed">
                <div className="max-w-lg space-y-4">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="event_series"
                        name="event_series"
                        type="checkbox"
                        checked={formQuestions.event_series}
                        onChange={(e) => {setField(e)}}
                        className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="event_series"
                        className="font-medium text-gray-700"
                      >
                        This is an event series
                      </label>
                      <p className="text-gray-500">
                        Create a reocurring multi day event or a series of events.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}

          <div className="grid grid-cols-3 gap-6">
            <div className="grid grid-cols-8 gap-8">
              <div className="col-span-12 md:col-span-12 sm:col-span-12">
                <label
                  htmlFor="event_name"
                  className="block w-full text-sm font-medium text-gray-700"
                >
                  Event Name
                </label>
                <input
                  type="text"
                  name="event_name"
                  required
                  id="event_name"
                  placeholder="Jacksonville Iceman Season 2021-2022"
                  value={values.event_name}
                  onChange={onChange}
                  autoComplete="event-name"
                  className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm`}
                />
                {errors.event_name && touched.event_name && (
                  <p className="mt-2 text-sm text-red-600" id="name-error">
                    This field is required.
                  </p>
                )}
              </div>
            </div>

            <div className="col-span-3">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <div className="mt-1">
                <textarea
                  id="event_description"
                  name="event_description"
                  rows={3}
                  value={values.event_description}
                  onChange={onChange}
                  className="shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Ice hockey"
                  defaultValue={""}
                />
                {errors.event_description && touched.event_description && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="description-error"
                  >
                    This field is required.
                  </p>
                )}
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Brief description for your the event or series. URLs are
                hyperlinked.
              </p>
            </div>

            {/* <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Photo
                  </label>
                  <div className="mt-1 flex items-center">
                    <span className="inline-block bg-gray-100 rounded-full overflow-hidden h-12 w-12">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                    <button
                      type="button"
                      className="ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                    >
                      Change
                    </button>
                  </div>
                </div> */}

            {/* <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Cover photo
                  </label>
                  <div className="mt-1 border-2 border-gray-300 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer bg-white rounded-md font-medium text-gemstoneblue-600 hover:text-gemstoneblue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gemstoneblue-500"
                        >
                          <span>Upload a file</span>
                          <input
                            value={formQuestions.files}
                            onChange={(e) => {setField(e)}}
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-500">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                </div> */}
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={() => {
              scrollTo(eventInfo);
            }}
            // type="submit"
            className="bg-white-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gemstoneblue hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
