// import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./containers/Dashboard";
import TopNav from "./containers/TopNav";
import Events from "./containers/Events";
// import Reports from "./containers/Reports";
import Team from "./containers/Team";
import Profile from './containers/Profile';
import Settings from './containers/Settings';
// import Calendar from "./containers/Calendar";
import ContentArea from "./containers/ContentArea";
import Login from "./containers/Login";
import ForgotPassword from "./containers/ForgotPassword";
import Register from "./containers/Register";
import NotFound from "./containers/NotFound";
import PrivateRoute from "./utils/privateRoute";
import { ProvideAuth } from "./utils/auth";
import Authorize from "./containers/Authorize";
import AgreementUpdate from "./containers/Agreement/Update";
import Agreement from "./containers/Agreement";
import ManageTeam from "./containers/TeamManagement";
import Invoices from "./containers/Invoices";
import Reviews from './containers/Reviews';
import { useDispatch, useSelector } from "react-redux";
import {
  isOpen,
  notificationDetails,
  dismissNotification,
} from "./containers/Notification/slice";
import Notification from "./components/Notification";
import './tailwind.css';
import withScope from './utils/withScope';
import { getUserDetails } from "./containers/Login/slice";

function App() {
  const open = useSelector(isOpen);
  const user = useSelector(getUserDetails);

  const content = useSelector(notificationDetails);

  const dispatch = useDispatch();

  return (
    <div>
      <ProvideAuth>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route exact path="/authorize" component={Authorize} />
            {/* <Route path="/agreement" component={withScope(user)(AgreementUpdate)} /> */}
            <Route path="/agreement" component={AgreementUpdate} />
            <TopNav>
              <ContentArea>
              <PrivateRoute>
                  <Route path="/invoices" component={withScope(user)(Invoices)} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route path="/agreements" component={withScope(user)(Agreement)} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route exact path="/" component={withScope(user)(Dashboard)} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route path="/events" component={withScope(user)(Events)} />
                </PrivateRoute>
                {/* <PrivateRoute>
                  <Route path="/reports" component={Reports} />
                </PrivateRoute> */}
                {/* <PrivateRoute>
                  <Route path="/calendar" component={Calendar} />
                </PrivateRoute> */}
                <PrivateRoute>
                  <Route path="/teams" component={withScope(user)(Team)} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route path="/reviews" component={withScope(user)(Reviews)} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route path="/profile" component={withScope(user)(Profile)} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route path="/manage-team" component={withScope(user)(ManageTeam)} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route path="/settings" component={withScope(user)(Settings)} />
                </PrivateRoute>
              </ContentArea>
            </TopNav>
            <Route exact path="/*" component={NotFound} />
          </Switch>
        </Router>
      </ProvideAuth>
      <Notification
        open={open}
        content={content}
        dispatch={dispatch}
        dismissNotification={dismissNotification}
      />
    </div>
  );
}

export default App;
