import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const HOSTNAME_STRING = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api`;
console.log('SETTING HOSTNAME ', HOSTNAME_STRING);
// This mimics the axios base configuration
const baseQuery = fetchBaseQuery({
    baseUrl: HOSTNAME_STRING,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('access_token')
      // we could also use the getState function to get the token
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `${token}`)
      }
  
      return headers
    },
})

  export default baseQuery;
  