import { Route, Switch, useRouteMatch } from "react-router-dom";
import EventList from "./List";
import CreateEvent from "./Create";
import ReviewEvent from './Review';
import EventDetails from "./Details";
import { instance } from "../../utils/axios";

export default function Events() {
  let { path, url } = useRouteMatch();

  return (
    <div className="h-screen">
      <Switch>
        <Route exact path={`${url}/`} component={EventList} />
        <Route exact path={`${url}/create`} component={CreateEvent} />
        <Route exact path={`${url}/detail/:uuid`} component={EventDetails} />
        <Route exact path={`${url}/review/:uuid`} component={ReviewEvent} />
      </Switch>
    </div>
  );
}

export function removeItemFrom(
  location,
  item,
  inputArray,
  setter,
  event,
  removeItemNotification
) {
  if (location === "event_config") {
    const remaining = inputArray.filter((id) => {
      if (id.id === item.id) {
        // PUT/POST config.id/eventid(uuid) -> use to lookup in database to find eventconfig --> remove eventconfigs from database from previous lookup
        const URL = `/api/events/${event.uuid}/event-config`;
        const response = instance.put(URL, {
          event_config_option_id: id.id,
        });
        response.then((data) => {
          removeItemNotification(data.data.message, data.data.updated);
        });
      }

      return id.id !== item.id;
    });
    setter(remaining);
  } else if (location === "event_staff") {
    const remaining = inputArray.filter((id) => {
      if (id.user.uuid === item.user.uuid) {
        const URL = `/api/event-roles`;
        try {
          const response = instance.put(URL, {
            user_id: id.user.uuid,
            event_id: event.uuid,
          });
          response.then((data) => {
            removeItemNotification(data.data.message, data.data.updated);
          });
        } catch (e) {}
      }
      return id.user.uuid !== item.user.uuid;
    });
    setter(remaining);
  } else if (location === "event_meeting") {
    const remaining = inputArray.filter((id) => {
      if (id.id === item.id) {
        const URL = `/api/event-meetings`;
        const response = instance.put(URL, {
          meeting_id: id.id,
          event_id: event.uuid,
        });
        response.then((data) => {
          removeItemNotification(data.data.message, data.data.updated);
        });
      }
      return id.name !== item.name;
    });
    setter(remaining);
  }
  // } else if (location === "event_schedule") {
  //   const remaining = inputArray.filter((id) => {
  //     return id.name !== item.name;
  //   });
  //   setter(remaining);
  // }
}
