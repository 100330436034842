import React from "react";
import { useRouteMatch } from "react-router-dom";

const ContentArea = (props) => {
  const { page_title, children } = props;
  const bodyClass = useRouteMatch("/events/detail")
    ? null
    : "bg-white rounded-lg shadow px-5 py-6 sm:px-6 h-full pb-48 overflow-x-hidden";

  return (
    <main className="-mt-32">
      <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <div className={bodyClass}>{children}</div>
      </div>
    </main>
  );
};

export default ContentArea;
