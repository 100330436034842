/**
 * This component displays a table of data.
 * @param collection The table collection if needed
 * @param fields The columns/fields of the table
 * @param loading Whether or not the data is still loading
 * @param actions The actions to be displayed in the table
 * @children The implementation specific markup that can't be made reusuable
 * @param remaining props Any props used by children
 * @returns 
 */
const ListView = ({collection, fields, loading, actions, children, ...props}) => {
    if (!collection || !fields) {
        return <div>Loading...</div>
    }
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mr-4">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                    <table
                    className={`min-w-full divide-y divide-gray-200 ${
                        loading && "animate-pulse"
                    }`}
                    >
                    <thead className="bg-gray-50">
                        <tr>
                        {fields.map(field => (
                            <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            {field.label}
                        </th>
                        ))}       
                        { actions &&
                        <>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Actions
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                        </>
                        }
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {children}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ListView;
