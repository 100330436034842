const positions =
  [
    {
      id: 0,
      item: {
        name: 'Skystorm Production Trailer',
        description: 'A nice trailer with air conditioning and snacks.',
        department: 'Engineering',
        imageUrl:
          'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      date: '2020-01-07',
      dateFull: 'January 7, 2020',
      stage: 'Approved',
      href: '#',
    },
    {
      id: 1,
      item: {
        name: 'Scorebug',
        description: 'The kind of bugs pest control won\'t kill.',
        department: 'Engineering',
        imageUrl:
          'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      date: '2020-01-07',
      dateFull: 'January 7, 2020',
      stage: 'Approved',
      href: '#',
    },
    {
      id: 2,
      item: {
        name: 'Cameraman',
        description: 'John from the super market.',
        department: 'Engineering',
        imageUrl:
          'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      date: '2020-01-07',
      dateFull: 'January 7, 2020',
      stage: 'Approved',
      href: '#',
    },
    {
      id: 3,
      item: {
        name: 'Cordless Mic',
        description: 'Is this thing on?',
        department: 'Engineering',
        imageUrl:
          'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      date: '2020-01-07',
      dateFull: 'January 7, 2020',
      stage: 'Approved',
      href: '#',
    }
  ]
export default positions;