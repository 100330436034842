import { CheckCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { format, parseISO } from "date-fns";

export default function EventMeetingList(props) {
  const { collection, event, setter, removeItemFrom, removeItemNotification, people, meetingParticipants, handleRemoveMemberFromMeeting } =
    props;

  const getFirstAndLastName = (user_uuid) => {
    const person = people?.find((p) => p?.user?.uuid === user_uuid)?.user;
    return `${person?.first_name[0]}${person?.last_name[0]}`;
  }
  return (
    <div className="bg-white shadow overflow-hidden rounded-b-lg">
      <ul className="divide-y divide-gray-200">
        {collection.map((configuration, index, array) => (
          <li key={configuration?.name}>
            <a className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-gemstoneblue-600 truncate">
                        {configuration?.name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <span className="truncate">
                          {configuration?.location}
                        </span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                          Starts At{" "}
                          {`${format(
                            parseISO(configuration?.start_datetime),
                            "LLLL do, yyyy hh:mm aa"
                          )}`}
                          {/* <time dateTime={configuration?.start_datetime}>
                            {configuration?.start_datetime}
                          </time> */}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <CheckCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                              {meetingParticipants[index]?.map((member) => {
                              return (
                                <div>
                                <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
                                  <span className="text-xs font-medium leading-none text-white">{getFirstAndLastName(member.user_id)}</span>
                                </span>
                                <button
                                  type="button"
                                  className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gemstoneblue-400 hover:bg-gemstoneplaybook-200 hover:text-gemstoneblue-500 focus:outline-none focus:bg-gemstone-400 focus:text-white"
                                >
                                  <span className="sr-only">Remove member from meeting</span>
                                  <svg onClick={() => {handleRemoveMemberFromMeeting(member)}} className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                  </svg>
                                </button>
                              </div>
                              )
                            })
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button>
                    <TrashIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      onClick={(e) => {
                        removeItemFrom(
                          "event_meeting",
                          configuration,
                          array,
                          setter,
                          event,
                          removeItemNotification
                        );
                      }}
                    />
                  </button>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
