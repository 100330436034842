import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import Modal from "../../../components/Modal";
import EventConfigurations from "./configurations";
import people from "./sample_data/staff";
import { instance } from "../../../utils/axios";
import { useHistory } from "react-router-dom";
import Navigation from "./Navigation";
import EventDetails from "./EventDetails";
import Event from "./Event";
import EventCont from "./EventCont";
import EventConfig from "./EventConfig";
import EventStaff from "./EventStaff";
import EventMeeting from "./EventMeeting";
// import EventSchedule from "./EventSchedule";
import { startLoading } from "../../../rootSlice";
import { removeItemFrom } from "../index";
import eventState from "./initialEventState";
import {
  UserEventRole
} from "../../../components/AvatarTable";
import { handleNotification } from "../../Notification/slice";
import { useCreateEventMutation } from "../../../services/laravel";
import validateFields from "./validateFields";

/**
 *
 * @param ref A React.createRef to an <HTMLElement>
 * @returns An action that scrolls the user to the HTML element assigned at the ref.
 */
const scrollToRef = (ref) => {
  return window.scrollTo(0, ref.current.offsetTop);
};

function CreateEvent() {
  // const loading = useSelector(isLoading);
  const [loading, setLoading] = useState(false);
  // Get the dispatch object from redux to send actions to the store
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalData, setModalData] = useState([]);
  const axios = instance;
  const eventDetails = useRef(null);
  const eventInfo = useRef(null);
  const eventInfoContinued = useRef(null);
  const eventConfig = useRef(null);
  const eventStaff = useRef(null);
  const eventMeeting = useRef(null);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [eventConfigData, setEventConfigData] = useState([]);
  const [eventStaffRoleData, setEventStaffRoleData] = useState([]);
  const [eventScheduleData, setEventScheduleData] = useState([]);
  const [eventConfigOptionsData, setEventConfigOptionsData] =
    useState(EventConfigurations);
  const [eventStaffData, setEventStaffData] = useState([]);
  const [eventStaffAvailableData, setEventStaffAvailable] = useState(people);
  const [eventRoleData, setEventRoleData] = useState([]);
  const [eventMeetingData, setEventMeetingData] = useState([]);
  const [formQuestions, setFormQuestions] = useState(eventState);
  const [createEventMutation] = useCreateEventMutation();

  useEffect(() => {
    // Run one time on page load
    // Dispatch loading
    dispatch(startLoading());
  }, []);

  useEffect(() => {
    const URL = `/api/event-roles`;
    axios.get(URL).then((response) => {
      setEventRoleData(response.data);
    });
  }, []);

  // async staff setter
  useEffect(() => {
    const URL = `/api/users`;
    axios.get(URL).then((response) => {
      setEventStaffAvailable(response.data);
    });
  }, []);

  useEffect(() => {
    const URL = `/api/event-config-options`;
    axios.get(URL).then((response) => {
      setEventConfigOptionsData(response.data);
    });
  }, []);

  /**
   *
   * @param type String for modal type key
   *
   * Modal is for populating data given an input
   * context. The input context also contains pick
   * lists of configuration data, onClick handlers,
   * and setter state fields.
   *
   * Sets:
   *  - Modal Content
   *    - Based on type input
   *    - Modal content is a string representing the modal type
   *  - Modal Data
   *    - This is the data used by the modal for user to pick from
   *  - Modal Open
   *
   */
  const popModalFor = (type) => {
    setModalContent(type);
    switch (type) {
      case "eventConfiguration":
        setModalData(eventConfigOptionsData);
        break;
      case "eventStaff":
        setModalData([
          { staff: eventStaffAvailableData, roles: eventRoleData },
        ]);
        break;
      case "eventMeeting":
        setModalData(eventMeetingData);
        break;
      case "eventSchedule":
        setModalData(eventScheduleData);
        break;
      default:
        break;
    }
    /**
     * Pop the modal
     */
    setOpen(true);
  };

  /**
   *
   * @param e Window Event
   * @returns Set react field state for form submission schema
   *
   * Set the field value for the form submission. Inspect the name and
   * value from the window event. Reused by all form containers and components.
   *
   */
  const setFieldByName = (name, value) => {
    return setFormQuestions({
      ...formQuestions,
      [name]: value,
    });
  };

  /**
   *
   * @param e Window Event
   * @returns Set react field state for form submission schema
   *
   * Set the field value for the form submission. Inspect the name and
   * value from the window event. Reused by all form containers and components.
   *
   */
  const setField = (e) => {
    return setFormQuestions({
      ...formQuestions,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  /**
   * onClick handler for Event Configuration
   * Sets the state array for each click of the Modal tile
   * Receives entire eventConfigData object and sets directly
   * TODO: Wire up for suming the total instead of inserting extra
   * rows.
   * @param data EventConfig object
   * type EventConfig = {
   * cost: String
   * created_at: String
   * id: Number
   * name: String
   * status: String
   * tenant_id: Number
   * type: String
   * updated_at: String
   * };
   */
  const handleSetEventConfigData = (e, data) => {
    setEventConfigData([...eventConfigData, data]);
  };

  /**
   *
   * @param data Staff UUID
   * Set EventStaffData state array with the value
   * passed. TODO: maybe delete this? Currently commented out
   * on click handler in ModalList.
   */
  const handleSetEventStaff = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    const staffIndex = eventStaffAvailableData.findIndex(function (staff) {
      return staff.uuid === data;
    });
    setEventStaffData([...eventStaffData, eventStaffAvailableData[staffIndex]]);
  };

  /**
   *
   * @param e Window Event
   * @param data Object from EventStaff
   * Create submission transformation
   * to build server facing schema. We
   * were using events but that went out
   * the window when we had mixed id/uuid
   * usage.
   */
  const handleAssignStaffRole = (e, data) => {
    const eventStaffRoleDataCopy = [...eventStaffRoleData];
    data.role_id = e.target.value;

    // Should we even mutate the eventStaffData?
    const roleIndex = eventRoleData.findIndex(
      (role) => role.uuid === data.role_id
    );
    const existingItem = eventStaffRoleData.findIndex(
      (staff) => staff.user.uuid === data.uuid
    );

    const person: UserEventRole = {
      user: {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        uuid: data.uuid,
        phone: data.phone,
        status: data.status,
      },
      event_role: {
        role_name: eventRoleData[roleIndex].role_name,
        uuid: eventRoleData[roleIndex].uuid,
      },
      status: null,
    };

    if (existingItem >= 0) {
      eventStaffRoleDataCopy[existingItem] = person;
    } else {
      eventStaffRoleDataCopy.push(person);
    }

    // Set EventStaffRoleData with both the person and role object
    setEventStaffRoleData([...eventStaffRoleDataCopy]);
  };

  const handleSetEventSchedule = (name, time) => {
    setEventScheduleData([...eventScheduleData, { name: name, time: time }]);
  };

  // Database also supports reoccuring boolean and type
  const handleSetEventMeeting = (name, time, location, members) => {
    setEventMeetingData([...eventMeetingData, { name: name, time: time, location: location, particpants_required: members }]);
  };

  /**
   * Form submission creates full-details schema
   */
  const onSubmitHandler = async (values, isValid) => {
    const errors = validateFields(values);
    dispatch(
      handleNotification()
    );
    setLoading(true);
    const configs = [];
    const meetings = [];
    const roles = [];
    eventConfigData.forEach((config) => {
      configs.push(config.id);
    });
    eventMeetingData.forEach((config) => {
      meetings.push(config);
    });
    eventStaffRoleData.forEach((config: UserEventRole) => {
      roles.push({
        user_id: config.user.uuid.toString(),
        role_id: config.event_role.uuid,
      });
    });
    const body = {
      name: values.event_name,
      schedule: eventScheduleData.toString(),
      status: "New",
      location: values.event_street_address,
      address: values.event_street_address,
      date: values.event_date,
      call_datetime: values.event_call_datetime,
      facilities: values.event_facilities,
      parking: values.event_parking,
      // schedule: values.event_schedule,
      credentials: values.event_credentials,
      notes: values.event_notes,
      transmission_datetime: values.event_transmission_datetime,
      channel_assignment: values.event_channel_assignment,
      transmission_company: values.event_transmission_company,
      source: values.event_source,
      ready_datetime: values.event_ready_datetime,
      additional_fields: "{}",
      budget: values.event_budget,
      created_by: "me",
      eventconfigs: configs,
      meetings: meetings,
      roles,
    };
    try {
      const destination = '/events';
      const result = await createEventMutation(body);
      if (result) {
        history.push(destination);
      }
    } catch (e) {
      scrollTo(eventDetails)
    }
  };

  const scrollTo = (ref) => scrollToRef(ref);

  /**
   * TODO: Export this for use on view event details.
   *
   */


  return (
    <div
      className={`lg:grid lg:grid-cols-12 lg:gap-x-5 ${
        loading && "animate-pulse"
      }`}
    >
      <Formik
        initialValues={eventState}
        validate={(values) => validateFields(values)}
        onSubmit={onSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          submitForm,
          isSubmitting,
          ...props
          /* and other goodies */
        }) => (
          <>
            <Navigation
              scrollTo={scrollTo}
              eventDetails={eventDetails}
              eventInfo={eventInfo}
              eventInfoContinued={eventInfoContinued}
              eventConfig={eventConfig}
              eventStaff={eventStaff}
              eventMeeting={eventMeeting}
            />
            <form
              onSubmit={(e) => e.preventDefault()}
              className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9"
            >
              <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <EventDetails
                  scrollTo={scrollTo}
                  eventDetails={eventDetails}
                  eventInfo={eventInfo}
                  values={values}
                  touched={touched}
                  onChange={handleChange}
                  errors={errors}
                />

                <Event
                  eventInfo={eventInfo}
                  formQuestions={formQuestions}
                  values={values}
                  touched={touched}
                  onChange={handleChange}
                  errors={errors}
                  setField={setField}
                  eventInfoContinued={eventInfoContinued}
                  scrollTo={scrollTo}
                />

                <EventCont
                  eventInfoContinued={eventInfoContinued}
                  formQuestions={formQuestions}
                  values={values}
                  touched={touched}
                  onChange={handleChange}
                  errors={errors}
                  setField={setField}
                  setFieldValue={setFieldValue}
                  setFieldByName={setFieldByName}
                  eventConfig={eventConfig}
                  scrollTo={scrollTo}
                />

                <EventConfig
                  eventConfig={eventConfig}
                  eventStaff={eventStaff}
                  eventMeeting={eventMeeting}
                  popModalFor={popModalFor}
                  removeItemFrom={removeItemFrom}
                  setter={setEventConfigData}
                  eventConfigData={eventConfigData}
                  scrollTo={scrollTo}
                />

                <EventStaff
                  eventStaff={eventStaff}
                  popModalFor={popModalFor}
                  removeItemFrom={removeItemFrom}
                  setter={setEventStaffRoleData}
                  eventStaffData={eventStaffRoleData}
                  scrollTo={scrollTo}
                  eventMeeting={eventMeeting}
                  roles={eventRoleData}
                />

                <EventMeeting
                  eventMeeting={eventMeeting}
                  values={values}
                  errors={errors}
                  removeItemFrom={removeItemFrom}
                  setter={setEventMeetingData}
                  eventMeetingData={eventMeetingData}
                  handleSubmit={onSubmitHandler}
                  eventStaffData={eventStaffRoleData}
                  {...props}
                />
                {/* <EventSchedule
              eventSchedule={eventScheduleData}
              popModalFor={popModalFor}
              removeItemFrom={removeItemFrom}
              setter={setEventScheduleData}
              eventScheduleData={eventScheduleData}
              handleSubmit={handleSubmit}
            /> */}
              </div>
            </form>
          </>
        )}
      </Formik>
      <Modal
        modalData={modalData}
        collection={EventConfigurations}
        ecOCH={handleSetEventConfigData}
        eventScheduleOnClick={handleSetEventSchedule}
        eventStaffOnClick={handleSetEventStaff}
        eventMeetingOnClick={handleSetEventMeeting}
        assignRole={handleAssignStaffRole}
        type={modalContent}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}

export default CreateEvent;
