import GoogleMapReact from 'google-map-react';
import { useState } from 'react';

const AnyReactComponent = ({ lat, lng, text }) => <div>{text}</div>;

export default function EventMap({address}){
  const [marker, setMarker] = useState({
    lat: null,
    lng: null,
  })
  const defaultProps = {
    center: {
      lat: 30,
      lng: -81.002
    },
    zoom: 11
  };

  const initGeocoder = ({ map, maps }) => {
    const Geocoder = new maps.Geocoder();
    Geocoder.geocode( { 'address': address}, function(results, status) {
      if (status == 'OK') {
        setMarker(results[0].geometry.location)
        map.setCenter(results[0].geometry.location);
        var marker = new maps.Marker({
            map: map,
            position: results[0].geometry.location
        });
      } else {
        // setMarker({lat: null, lng: null})
      }
    });
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}` }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={initGeocoder}
        yesIWantToUseGoogleMapApiInternals
      >
        {marker.lat !== null && <AnyReactComponent
          lat={marker.lat}
          lng={marker.lng}
          text="Event Location"
        />}
      </GoogleMapReact>
    </div>
  );
}
