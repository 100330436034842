import SimpleDropdown from "../../../../components/SimpleDropdown";

const collection = [
  {
    id: 1,
    name: 'Coming Soon'
  }
]

export default function Event(props) {
    const { eventInfo, formQuestions, setField, eventInfoContinued, scrollTo, values, touched, errors, onChange, onBlur } = props;
    const isSeries = formQuestions.event_series === true;
    return (
        <div>
          <div
            ref={eventInfo}
            className="shadow sm:rounded-md sm:overflow-hidden"
          >
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  { isSeries ? 'Series Information' : 'Event Information'}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Fill in basic details about the { isSeries ? 'series' : 'event' }
                </p>
              </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="event_contact_first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select from existing Event Venue
                </label>
                <SimpleDropdown options={collection} disabled/>
                </div>
              </div>
              
               { isSeries &&
                <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="event_series"
                        name="event_series"
                        type="checkbox"
                        checked={formQuestions.event_series}
                        onChange={(e) => {setField(e)}}
                        className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="event_series"
                        className="font-medium text-gray-700"
                      >
                        Reuse venue information.
                      </label>
                      <p className="text-gray-500">
                        Apply the below event venue information for all events in the series.
                        <span className="text-gray-500 hidden">This can be changed later</span>
                      </p>
                    </div>
                  </div> }

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="event_contact_first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Contact First Name
                  </label>
                  <input
                    type="text"
                    value={values.event_contact_first_name}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    name="event_contact_first_name"
                    id="event_contact_first_name"
                    autoComplete="event-contact-first-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                  {errors.event_contact_first_name && touched.event_contact_first_name &&
                    <p className="mt-2 text-sm text-red-600" id="event-contact-first-name-error">
                      This field is required.
                    </p>
                    }
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="event_contact_last_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Contact Last Name
                  </label>
                  <input
                    type="text"
                    value={values.event_contact_last_name}
                    onChange={onChange}
                    onBlur={onBlur}
                    name="event_contact_last_name"
                    id="event_contact_last_name"
                    autoComplete="event-contact-last-name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="email_address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Contact Email address
                  </label>
                  <input
                    type="text"
                    value={values.event_contact_email_address}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    name="event_contact_email_address"
                    id="event_contact_email_address"
                    autoComplete="email"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                  {errors.event_contact_email_address && touched.event_contact_email_address &&
                    <p className="mt-2 text-sm text-red-600" id="name-error">
                      This field is required.
                    </p>
                    }
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country / Region
                  </label>
                  <select
                    id="event_country"
                    name="event_country"
                    defaultValue="United States"
                    value={values.event_country}
                    onChange={onChange}
                    autoComplete="country"
                    className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  >
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                    <option value="Mexico">Mexico</option>
                  </select>
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="street_address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Street address
                  </label>
                  <input
                    type="text"
                    name="event_street_address"
                    required
                    value={values.event_street_address}
                    onChange={onChange}
                    onBlur={onBlur}
                    id="event_street_address"
                    autoComplete="street-address"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                  {errors.event_street_address && touched.event_street_address &&
                    <p className="mt-2 text-sm text-red-600" id="name-error">
                      This field is required.
                    </p>
                    }
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    required
                    value={values.event_city}
                    onChange={onChange}
                    onBlur={onBlur}
                    name="event_city"
                    id="event_city"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                  {errors.event_city && touched.event_city &&
                    <p className="mt-2 text-sm text-red-600" id="name-error">
                      This field is required.
                    </p>
                    }
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State / Province
                  </label>
                  <input
                    type="text"
                    required
                    value={values.event_state}
                    onChange={onChange}
                    onBlur={onBlur}
                    name="event_state"
                    id="event_state"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                  {errors.event_state && touched.event_state &&
                    <p className="mt-2 text-sm text-red-600" id="name-error">
                      This field is required.
                    </p>
                    }
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="postal_code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ZIP / Postal
                  </label>
                  <input
                    type="text"
                    required
                    value={values.event_postal_code}
                    onChange={onChange}
                    onBlur={onBlur}
                    name="event_postal_code"
                    id="event_postal_code"
                    autoComplete="postal-code"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                  {errors.event_postal_code && touched.event_postal_code &&
                    <p className="mt-2 text-sm text-red-600" id="name-error">
                      This field is required.
                    </p>
                    }
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={() => {
                  scrollTo(eventInfoContinued);
                }}
                // type="submit"
                className="bg-white-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gemstoneblue hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
              >
                Next
              </button>
            </div>
          </div>
        </div>
    )
}