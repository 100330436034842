import { useState, useEffect } from 'react';
import { useGetTenantUsersQuery, useInviteUserToTeamMutation, useRemoveUserFromTeamMutation } from "../../services/laravel"

export default function ManageTeam() {
    const [inviteUser, {isLoading}] = useInviteUserToTeamMutation();
    const [removeUser, {isLoading: isRemoving}] = useRemoveUserFromTeamMutation();
    const {data} = useGetTenantUsersQuery({});
    const [results, setResults] = useState(data || []);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('User');

    const handleInvite = async (event, inviteObj) => {
      event.preventDefault();
      const user = await inviteUser(inviteObj);
      if ("data" in user) {
        setEmail('');
        setRole('');
      }
    }

    const handleRemove = async (event, removeObj) => {
      event.preventDefault();
      const removedUser = await removeUser(removeObj);
    }

    useEffect(() => {
      setResults(data)
    }, [data])

    const EmptyList = () => {
      return (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <h2 className="mt-2 text-lg font-medium text-gray-900">Add team members</h2>
          <p className="mt-1 text-sm text-gray-500">You haven’t added any team members to your project yet.</p>
        </div>
      )
    }

    return (
      <form className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Team Management</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Invite new team members to collaborate.
              </p>
            </div>
            {results && results.length === 0 && <EmptyList />}
            <div>
              <div className="flow-root mt-6">
                <ul role="list" className="-my-5 divide-y divide-gray-200">
                  {results && results.map((person) => (
                    <li key={person?.email} className="py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img className="h-8 w-8 rounded-full" src={person?.imageUrl} alt="" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">{person?.first_name ? `${person.first_name} ${person.last_name}` : 'Name not provided'}</p>
                          <p className="text-sm text-gray-500 truncate">{person?.email || 'Email not provided'}</p>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                          >
                            View
                          </a>
                        </div>
                        <div>
                          {/* <a
                            href="#"
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Remove
                          </a> */}
                          <button
                            onClick={(e) => {handleRemove(e, {email: person.email, role})}}
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="mt-6">
                <a
                  href="#"
                  className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  View all
                </a>
              </div> */}
            </div>
          </div>
          <div className="bg-white shadow sm:rounded-lg max-w-full shadow-lg mt-8 py-8 w-full">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Send a new invite</h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Enter the role and email of the user you want to invite to the team.</p>
              </div>
              <div className="w full">
                <form className="mt-5 sm:items-center">
                  <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                    Role
                  </label>
                  <select
                    id="role"
                    name="role"
                    value={role}
                    onChange={(event) => {setRole(event.target.value)}}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm rounded-md"
                    defaultValue="User"
                  >
                    <option value="Admin">Admin</option>
                    <option value="User">User</option>
                    <option value="Contractor">Contractor</option>
                  </select>
                <div className="w-full sm:max-w-xs">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-8">
                    Email
                  </label>
                  <input
                    type="email"
                    onChange={(event) => {setEmail(event.target.value)}}
                    value={email}
                    name="email"
                    id="email"
                    className="shadow-sm mb-8 focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="you@example.com"
                  />
                </div>
                <button
                  type="submit"
                  onClick={(e) => {handleInvite(e, {email, role})}}
                  className={`mt-8 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500 sm:mt-0 sm:w-auto sm:text-sm`}
                >
                  { isLoading ? 'Inviting...' : 'Invite'}
                </button>
              </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              Save
            </button>
          </div>
        </div> */}
      </form>
    )
  }
  