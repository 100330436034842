import React from "react";
import Toggle from "./Toggle";
import Rating from 'react-rating';
import { StarIcon } from '@heroicons/react/outline'

export type User = {
  email: string;
  image?: string;
  first_name: string;
  last_name: string;
  phone: string;
  status: string;
  uuid: string;
  rating?: any;
};

export type EventRole = {
  role_name: string;
  uuid: string;
};

export type UserEventRole = {
  user: User;
  event_role: EventRole;
  status: string;
  agreement_id?: string;
  user_event_role_id?: string;
  rating?: any;
};

export default function ReviewEventStaff(props: {
  people: UserEventRole[];
  event?: any;
  submitReviewHandler?: Function;
  toggleContractorPresent?: Function;
  loading?: boolean;
  onClick?: Function;
  handleRating?: Function;
  createTeam?: boolean;
  teamName?: String;
  children?: any;
}) {
  const {
    people,
    submitReviewHandler,
    toggleContractorPresent,
    event,
    onClick,
    loading,
    handleRating,
    children
  } = props;

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role/Position
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Cell Phone
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Rating
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Pay Contractor
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {people?.map((person: UserEventRole, index, people) => (
                    <tr key={person?.user.email}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={person?.user.image}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {person?.user.first_name +
                                " " +
                                person?.user.last_name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {person?.user.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {person?.event_role.role_name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {person?.user.phone}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          <Rating
                            emptySymbol={<StarIcon className="w-5 h-5" />}
                            placeholderRating={3}
                            step={1}
                            initialRating={person.rating}
                            onChange={(val) => {handleRating(val, person)}}
                            placeholderSymbol={<StarIcon className="w-5 h-5 bg-gemstone-400" />}
                            fullSymbol={<StarIcon className="w-5 h-5 bg-gemstone-400" />}
                            start={0}
                            stop={5}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="ml-4 mt-2 flex-shrink-0">
                          <Toggle toggleContractorPresent={toggleContractorPresent} person={person} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              {children}
            </div>
            <div className="px-96 py-4">
              <button
                  type="button"
                  onClick={() => {submitReviewHandler(people)}}
                  className={`${loading ? 'animate-pulse' : ''}inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500`}
                >
                {loading ? "Completing Event..." : "Complete Event"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
