import { Fragment, useMemo, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
// import { SearchIcon } from "@heroicons/react/solid";
// import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
// import { useAuth0 } from "@auth0/auth0-react";
import { Link, useHistory } from "react-router-dom";
import routes from "../../utils/routes";
import { useSelector } from "react-redux";
import { getUserDetails } from "../Login/slice";
import { hasRole } from "../../utils/auth";
import { scopeMap } from "../../utils/withScope";
import { useGetUserByIdQuery } from "../../services/user";

const profile = [
  {id: 1, href: '/profile', title: "Your Profile"},
  {id: 2, href: '/settings', title: "Settings"},
  {id: 3, href: '/manage-team', title: "Manage Team"},
  {id: 4, href: '/login', title: "Sign out"}];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const unsetIndexes = (index, array) => {
  array.forEach((item) => {
    if (item.current === true && array[index] !== item) {
      item.current = false;
    } else {
      item.current = false;
    }
    if (item === array[index]) {
      item.current = true;
    }
  });

  return array;
};

export default function TopNav(props) {
  const { children } = props;
  // const { logout } = useAuth0();
  const userDetails = useSelector(getUserDetails);
  const [profileLinks, setProfileLinks] = useState(profile);
  const { data } = useGetUserByIdQuery({ uuid: userDetails.uuid });
  const [navigation, setNavigation] = useState([
    { name: "Dashboard", href: "/", current: true },
    { name: "Teams", href: "/teams", current: false },
    { name: "Events", href: "/events", current: false },
    { name: "Reviews", href: "/reviews", current: false },
    { name: "Invoices", href: "/invoices", current: false },
    { name: "Agreements", href: "/agreements", current: false },
    {
      name: "Invoice Details",
      href: "/invoices/" + /./,
      current: false,
      visible: false,
    },
    // { name: "Calendar", href: "/calendar", current: false },
    // { name: "Reports", href: "/reports", current: false },
    {
      name: "Event Details",
      href: "/events/" + /./,
      current: false,
      visible: false,
    },
  ]);
  const pageTitle = routes[window.location.pathname];
  const history = useHistory();
  const userNameSet = userDetails.first_name !== undefined && userDetails.first_name !== null;

  useMemo(() => {
    // perform user role check to filter navigation items
    const filteredNavigation = navigation.filter((item) => {
      return hasRole(userDetails, scopeMap[item.name]);
    })
    const filteredProfile = profileLinks.filter((item) => {
      return hasRole(userDetails, scopeMap[item.title]);
    })
    setNavigation(filteredNavigation);
    setProfileLinks(filteredProfile);
  }, [userDetails]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-gemstoneblue pb-32">
        <Disclosure
          as="nav"
          className="bg-gemstoneblue border-b border-gemstoneroyal-300 border-opacity-25 lg:border-none"
        >
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-gemstoneroyal-400 lg:border-opacity-25">
                  <div className="px-2 flex items-center lg:px-0">
                    <div className="flex-shrink-0">
                      <img
                        className="block h-8 w-8"
                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-300.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="hidden lg:block lg:ml-10">
                      <div className="flex space-x-4">
                        {navigation.map(
                          (item, index) =>
                            item.visible !== false && (
                              <Link
                                key={item.name}
                                to={item.href}
                                onClick={() => {
                                  const navigationCopy = unsetIndexes(index, [
                                    ...navigation,
                                  ]);
                                  setNavigation(navigationCopy);
                                }}
                                className={classNames(
                                  item.current
                                    ? "bg-gemstoneroyal text-white rounded-md py-2 px-3 text-sm font-medium"
                                    : "text-white hover:bg-gemstone-400 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                )}
                              >
                                {/* Current: "bg-gemstoneroyal text-white", Default: "text-white hover:bg-gemstone-400 hover:bg-opacity-75" */}

                                {item.name}
                              </Link>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  {
                    // hiding search until we have a search feature
                  }
                  {/* <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                    <div className="max-w-lg w-full lg:max-w-xs">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative text-gray-400 focus-within:text-gray-600">
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search"
                          disabled
                          className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gemstoneroyal-600 focus:ring-white focus:border-white sm:text-sm"
                          placeholder="Search"
                          type="search"
                          name="search"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gemstoneblue p-2 rounded-md inline-flex items-center justify-center text-gemstoneblue-200 hover:text-white hover:bg-gemstone-400 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gemstoneroyal-600 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:block lg:ml-4">
                    <div className="flex items-center">
                      {
                        // hiding 
                      }
                      {/* <button className="bg-gemstoneblue flex-shrink-0 rounded-full p-1 text-gemstoneblue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gemstoneroyal-600 focus:ring-white">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative flex-shrink-0">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="bg-gemstoneblue rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gemstoneroyal-600 focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="rounded-full h-8 w-8"
                                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                  alt=""
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                {profileLinks.map((item) => {
                                  return (
                                  <Menu.Item key={item.id}>
                                    {({ active }) => { 
                                      return (
                                        <Link
                                          key={item.id}
                                          className="block rounded-full py-2 px-4 text-sm text-gray-700 m-auto"
                                          to={item.href}
                                          onClick={(() => {
                                            if (item.title === "Sign out") {
                                              window.location.href = "/";
                                              localStorage.removeItem('access_token');
                                              history.push('/login');
                                            }
                                          })}
                                          >
                                            {item.title}
                                        </Link>
                                      )}}
                                  </Menu.Item>
                                  )})}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item, itemIdx) =>
                    itemIdx === 0 ? (
                      <Fragment key={item.name}>
                        {/* Current: "bg-gemstoneroyal text-white", Default: "text-white hover:bg-gemstone-400 hover:bg-opacity-75" */}
                        <Link
                          to={item.href}
                          className="bg-gemstoneroyal text-white block rounded-md py-2 px-3 text-base font-medium"
                        >
                          {item.name}
                        </Link>
                      </Fragment>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="text-white hover:bg-gemstone-400 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                      >
                        {item.name}
                      </Link>
                    )
                  )}
                </div>
                <div className="pt-4 pb-3 border-t border-gemstoneroyal-700">
                  <div className="px-5 flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="rounded-full h-10 w-10"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">
                        { userNameSet ? `${userDetails.first_name} ${userDetails.last_name}` : 'Unknown User' }
                      </div>
                      <div className="text-sm font-medium text-gemstoneblue-300">
                        {userNameSet ? `${userDetails.email}` : 'Unknown Email'}
                      </div>
                    </div>
                    {
                      // hiding until we have notifications
                    }
                    {/* <button className="ml-auto bg-gemstoneblue flex-shrink-0 rounded-full p-1 text-gemstoneblue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gemstoneroyal-600 focus:ring-white">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {profile.map((item) => (
                      <Link
                        to={item.href}
                        className="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-gemstone-400 hover:bg-opacity-75"
                      >
                        {item.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <header className="py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between">
            <h1 className="text-3xl font-bold text-white">{pageTitle}</h1>
            {pageTitle === "Events" && hasRole(userDetails, ['Tenant Admin']) && (
              <Link to="/events/create">
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                >
                  Create Event
                </button>
              </Link>
            )}
            {pageTitle === "Event Crews" && hasRole(userDetails, ['Tenant Admin']) && (
              <Link to="/teams/create">
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                >
                  Create Event Crew
                </button>
              </Link>
            )}
          </div>
        </header>
      </div>

      {children}
    </div>
  );
}
