import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
// import Dropdown from '../Dropdown';
import ModalList from "../ModalList";
import GenericModal from "../GenericModal";
import { useHistory } from "react-router-dom";

export default function Modal(props) {
  const {
    modalData,
    open,
    setOpen,
    ecOCH,
    eventStaffOnClick,
    eventMeetingOnClick,
    type,
    eventScheduleOnClick,
    assignRole,
    people
  } = props;
  const cancelButtonRef = useRef();

  /**
   *
   * @param type String key for onClick handler setting
   * @returns an onClick handler that is immediately executed
   */
  const selectClickHandler = (type) => {
    let eventHandler;
    switch (type) {
      case "eventConfiguration":
        eventHandler = ecOCH;
        break;
      case "eventStaff":
        eventHandler = eventStaffOnClick;
        break;
      case "eventMeeting":
        eventHandler = eventMeetingOnClick;
        break;
      case "eventSchedule":
        eventHandler = eventScheduleOnClick;
        break;
      default:
        break;
    }
    return eventHandler;
  };

  /**
   * Modal done button. Useful to close the modal
   * and other mysterious things.
   */
  const doneButton = (e) => {
    setOpen(false);
    // if (type === 'eventMeeting' || type === 'eventSchedule') {
    //   selectClickHandler(type)(e)
    // }
  };

  const getModalText = () => {
    switch(type) {
      case "eventConfiguration":
        return "Select the item to add to your event configuration.";
      case "eventStaff":
        return `Select the staff you would like to invite to your event.
        Use the dropdown to select the role the staff member will perform at the event.`;
      case "eventMeeting":
        return "Select the meeting you would like to add to your event.";
      case "eventSchedule":
        return "Create a schedule you would like to add to your event.";
      default:
        return "Add an item";
    }
  }

  const CTA = () => {
    return (
      <>
        <button type="button" className="items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500">
          <QuestionMarkCircleIcon onClick={() => {setCTAOpen(!ctaOpen)}}className="h-5 w-5"/>
        </button>
      </>
    )
  }

  const getHelpModalTitle = (type) => {
    switch(type) {
      case "eventConfiguration":
        return "Event Configuration";
      case "eventStaff":
        return "Event Staff";
      case "eventMeeting":
        return "Event Meetings";
      case "eventSchedule":
        return "Event Schedules";
      default:
        return "Add an item";
    }
  }

  const getHelpContent = (type) => {
    switch(type) {
      case "eventStaff":
        return (
          <>
            <span className="text-sm text-gray-500">
              Don't see the staff you would like to invite? Invite them by email.
            </span>
            <button
              type="button"
              onClick={() => {history.push('/manage-team')}}
              className="block mt-8 align-center m-auto items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              Go to invite page.
            </button>
          </>
        );
      case "eventConfiguration":
        return (
          <>
            <span className="text-sm text-gray-500">
              Add equipment, food, and other items to your event. Use the configuration editor to customize your available configuration items.
            </span>
            <button
              type="button"
              onClick={() => {history.push('/help')}}
              className="block mt-8 align-center m-auto items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              Go to config customizations.
            </button>
          </>
        );
      case "eventMeeting":
        return (
          <>
            <span className="text-sm text-gray-500">
              Create event meetings for your staff to attend during the before, during or after the event.
            </span>
            <button
              type="button"
              onClick={() => {history.push('/help')}}
              className="block mt-8 align-center m-auto items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              Learn more.
            </button>
          </>
        );
      case "eventSchedule":
        return (
          <>
            <span className="text-sm text-gray-500">
              Don't see the staff you would like to invite? Invite them by email.
            </span>
            <button
              type="button"
              onClick={() => {history.push('/manage-team')}}
              className="block mt-8 align-center m-auto items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              Go to invite page
            </button>
          </>
        );
      default:
        return (
          <>
            <span className="text-sm text-gray-500">
              Contact support for help.
            </span>
          </>
        );
    }       
  }
  const history = useHistory();
  const [ctaOpen, setCTAOpen] = useState(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div className="flex">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 flex-1"
                    >
                      Add Item
                    </Dialog.Title>
                    <CTA />
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {getModalText()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ml-8 mr-8 mt-8 mb-8">
                  <ModalList
                    collection={modalData}
                    onClickHandler={selectClickHandler(type)}
                    onChangeHandler={assignRole}
                    type={type}
                    people={people}
                  />
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 mb-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={(e) => doneButton(e)}
                  ref={cancelButtonRef}
                >
                  Done
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      <GenericModal setOpen={setCTAOpen} open={ctaOpen} modalText={getHelpModalTitle(type)} modalSubText="">
        {getHelpContent(type)}
      </GenericModal>
      </Dialog>
    </Transition.Root>
  );
}
