import { instance } from "../../../utils/axios";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import ListView from './ListView';
import { useDispatch } from "react-redux";
import { handleNotification, showFailure, showSuccess } from "../../Notification/slice";

export default function InvoiceList() {
  const axios = instance;
  let { url } = useRouteMatch();
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const URL = `/api/invoices`;
    const agreementRequest = axios.get(URL);
    try {
      agreementRequest.then((eventListRequestResponse) => {
        setInvoices(eventListRequestResponse.data);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const handleDelete = async (team) => {
    setLoading(true);
    const uuid = team.uuid;
    dispatch(handleNotification());
    const remaining = invoices.filter((id) => {
      if (id.uuid === team.uuid) {
        const URL = `/api/invoices/${uuid}`;
        try {
          const response = axios.delete(URL);
          response.then((data) => {
            dispatch(
              showSuccess({
                heading: "Success",
                message: data.data.message,
                status: true,
              })
            );
          });
        } catch (e) {
          dispatch(
            showFailure({
              heading: "Failure!",
              message: "Something went wrong. Please try again.",
              status: false,
            })
          );
        }
      }

      return id.uuid !== uuid;
    });
    setInvoices(remaining);
    setLoading(false);
  };

  
  return (
      <>
        <ListView
          invoices={invoices}
          actions={true}
          loading={loading}
          url={url}
          handleDelete={handleDelete}
        />
      </>
  );
}
