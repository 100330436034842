const routes = {
    '': 'Dashboard',
    '/': 'Dashboard',
    '/login': 'Login',
    '/teams': 'Event Crews',
    '/calendar': 'Event Calendar',
    '/reviews': 'Reviews',
    '/invoices': 'Invoices',
    '/agreements': 'Agreements',
    '/reports': 'Reports',
    '/events': 'Events',
    '/profile': 'Profile',
    '/settings': 'Settings',
    '/manage-team': 'Manage Team',
    '/events/create': 'Create Event',
    '/events/detail': 'Event Details'
}

export default routes;
