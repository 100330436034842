import {
  CheckCircleIcon,
  // ChevronRightIcon,
  // CheckIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { format, parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../containers/Login/slice";
import { hasRole } from "../../utils/auth";

export default function EventConfigList(props) {
  const user = useSelector(getUserDetails);
  const { collection, removeItemFrom, removeItemNotification, setter, event } =
    props;

  if (!collection) {
    return <div>Loading...</div>;
  }
  return (
    <div className="bg-white shadow overflow-hidden rounded-b-lg">
      <ul className="divide-y divide-gray-200">
        {collection.map((configuration, index, array) => (
          <li key={configuration?.name}>
            <a className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-gemstoneblue-600 truncate">
                        {configuration?.name}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <span className="truncate">{configuration?.type}</span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                          Applied{" "}
                          {configuration?.created_at
                            ? `${format(
                                parseISO(configuration.created_at),
                                "LLLL do, yyyy"
                              )}`
                            : "just now"}
                          <time dateTime={configuration?.date}>
                            {configuration?.dateFull}
                          </time>
                        </p>
                        {hasRole(user, ['Tenant Admin']) && <p className="mt-2 flex items-center text-sm text-gray-500">
                          <CheckCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                          Deducted from budget ${configuration?.cost}
                        </p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button>
                    <TrashIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      onClick={(e) => {
                        removeItemFrom(
                          "event_config",
                          configuration,
                          array,
                          setter,
                          event,
                          removeItemNotification
                        );
                      }}
                    />
                  </button>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
