import { useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CreateTeam(props) {
  const { onChange, enabled, onChangeName } = props;
  if (!props) {
      return <></>
  }

  return (
    <Switch.Group as="div" className="flex items-center justify-between p-1 pt-8 pr-16">
      <span className="flex-grow flex flex-col">
        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
          Create a new team from the above list?
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
            Save this collection of staff for easy lookup later
        </Switch.Description>
      </span>
      {enabled &&
          <div className="p-2">
            <label htmlFor="team-name" className="block text-sm font-medium text-gray-700">
                Team Name
            </label>
            <div className="mt-1">
                <input
                type="text"
                name="team-name"
                onChange={onChangeName}
                id="team-name"
                className="shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="A+ Sharks Team"
                aria-describedby="team-name"
                />
            </div>
            <p className="mt-2 text-sm text-gray-500" id="team-name">
                You can change this later
            </p>
        </div>}
      <Switch
        checked={enabled}
        onChange={onChange}
        className={classNames(
          enabled ? 'bg-gemstoneblue' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}
