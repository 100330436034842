const eventState = {
    event_series: false,
    event_name: "",
    status: "New",
    event_description: "",
    files: [],
    event_contact_first_name: "",
    event_contact_last_name: "",
    event_contact_email_address: "",
    event_country: "",
    event_street_address: "",
    event_city: "",
    event_state: "",
    event_postal_code: "",
    event_date: "",
    event_call_datetime: "",
    event_facilities: "",
    event_parking: "",
    event_schedule: "",
    event_credentials: "",
    event_notes: "",
    event_transmission_datetime: "",
    event_channel_assignment: "",
    event_transmission_company: "",
    event_source: "",
    event_ready_datetime: "",
    event_additional_fields: "",
    event_budget: "",
    event_created_by: "",
    event_configs: [],
    event_meetings: [],
    event_roles: [],
  }

export default eventState;
