/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import { UserEventRole } from "../AvatarTable";

type Event = {
  uuid: string
}

type ModalProps = {
  open: boolean
  setOpen: Function
  person: UserEventRole
  defaultrate: number
  submitOffer: Function
  event: Event
  loading: boolean
  setOfferType: Function
  offerType: string
  terms: string
  setTerms: Function
}

export default function OfferModal(props:ModalProps) {
  const { 
    open,
    setOpen,
    person,
    defaultrate,
    submitOffer,
    event,
    loading,
    setOfferType,
    offerType,
    terms,
    setTerms
  } = props;
  const [offer, setOffer] = useState(defaultrate);
  const [additionalTerms, setAdditionalTerms] = useState(false);

  const handleOfferInput = (e) => {
    let regex = /[^0-9]/g;
    const val = e.target.value;
    if (val.match(regex)?.length > 0) {
      return;
    }
    setOffer(e.target.value);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={`fixed z-10 inset-0 overflow-y-auto`}
        open={open}
        onClose={() => {setOpen(false)}}
      >
        <div className={`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ${loading && 'animate-pulse'}`}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <ArrowCircleRightIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Make an offer
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm m-4 text-gray-500">
                      Send offer to {person.user.first_name} {person.user.last_name} for
                      role {person.event_role.role_name || "Not Provided"}
                    </p>
                  </div>
                  <div>
                    <div className="m-4">
                      <label htmlFor="offer_type" className="block text-sm font-medium text-gray-700">
                        Offer Type
                      </label>
                        <select
                          id="offer_type"
                          name="offer"
                          value={offerType}
                          onChange={(event) => {setOfferType(event.target.value)}}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm rounded-md"
                          defaultValue="fixed"
                        >
                          <option value="fixed">Fixed/Flat Rate</option>
                          <option value="hourly">Hourly</option>
                          <option value="daily">Daily</option>
                        </select>
                    </div>
                      <div className="col-span-6 m-4 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="rate"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Rate
                        </label>
                        <input
                          onChange={handleOfferInput}
                          type="text"
                          name="rate"
                          value={offer}
                          id="rate"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 m-4 sm:col-span-3 lg:col-span-2 flex">
                        <input
                          onChange={() => {setAdditionalTerms(!additionalTerms)}}
                          type="checkbox"
                          name="addlterms"
                          value={additionalTerms ? 'checked' : ''}
                          id="addlterms"
                          className="focus:ring-gemstoneblue-500 mr-4 h-4 w-4 text-gemstoneblue-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="addlterms"
                          className="block text-sm flex-50 font-medium text-gray-700"
                        >
                          Include Additional Terms, Conditions or Benefits
                        </label>
                      </div>
                      {
                        additionalTerms &&
                        <div className="col-span-6 m-4 sm:col-span-3 lg:col-span-2">
                          <label
                            htmlFor="terms"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Terms
                          </label>
                          <textarea
                            onChange={(e) => {setTerms(e.target.value)}}
                            name="terms"
                            value={terms}
                            id="terms"
                            className="shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 mt-2 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Includes hotel, rental car and flight."
                          />
                        </div>
                      }
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gemstoneblue text-base font-medium text-white hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500 sm:text-sm"
                  onClick={() =>
                    submitOffer(
                      person.event_role.uuid,
                      offer,
                      event.uuid,
                      person.user.uuid,
                      setOpen,
                      offerType,
                      terms
                    )
                  }
                >
                  {`${loading ? 'Making' : 'Make'} offer${loading ? '...' : ''}`}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}