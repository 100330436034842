import AvatarTable from "../../../../components/AvatarTable";

export default function EventStaff(props) {
  const {
    eventStaff,
    popModalFor,
    eventStaffData,
    scrollTo,
    eventMeeting,
    roles,
    removeItemFrom,
    setter,
  } = props;
  return (
    <div>
      <div ref={eventStaff} className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <div className="flex justify-content:space-between">
              <div className="flex-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Event Staff
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Add the employees and contractors you want to send offers to.
                </p>
              </div>
              <span className="flex-grow-1">
                <button
                  onClick={() => {
                    popModalFor("eventStaff");
                  }}
                  // type="submit"
                  className="bg-white-600 border border-gray-600 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gemstoneblue hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                >
                  Add Item
                </button>
              </span>
            </div>
          </div>

          <div className="mt-8 py-1">
            {/**
             * Render table of selected contractors
             * TODO: Modify rendering to support objects with role and
             * user uuid.
             */}
            <AvatarTable
              people={eventStaffData}
              roles={roles}
              removeItemFrom={removeItemFrom}
              setter={setter}
            />
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={() => {
              scrollTo(eventMeeting);
            }}
            // type="submit"
            className="bg-white-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gemstoneblue hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
