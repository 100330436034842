import { Route, Switch, useRouteMatch } from "react-router-dom";
import AgreementList from "./List";
import AgreementUpdate from "./Update";
import AgreementDetails from './Details';

export default function Agreements() {
  let { url } = useRouteMatch();
  return (
    <div className="h-screen">
      <Switch>
        <Route exact path={`${url}`} component={AgreementList} />
        <Route exact path={`${url}/detail/:uuid`} component={AgreementDetails} />
        <Route exact path={`${url}/:uuid`} component={AgreementUpdate} />
      </Switch>
    </div>
  );
}
