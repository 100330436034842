import { parseISO, format } from "date-fns";
import ActionMenu from "./ActionMenu";
import ListViewContainer from "../../../components/ListView";
import { useHistory } from "react-router-dom";

const ListView = ({ agreements, loading, actions, ...props }) => {
  const history = useHistory();
  if (!agreements) {
    return <div>Loading...</div>;
  }
  const fields = [
    {
      label: "Event",
      name: "event",
    },
    {
      label: "Person",
      name: "person",
    },
    {
      label: "Role",
      name: "role",
    },
    {
      label: "Rate",
      name: "rate",
    },
    {
      label: "Last Updated",
      name: "last-updated",
    },
  ];

  const navigateToAgreement = (e, agreement) => {
    if (e.target.tagName === "svg" || e.target.tagName === "path") {
      return;
    }
    history.push(`/agreements/detail/${agreement?.uuid}`);
  };

  return (
    <ListViewContainer
      collection={agreements}
      fields={fields}
      loading={loading}
      actions={actions}
      {...props}
    >
      {agreements.map((agreement, i) => {
        const formattedDate = format(
          parseISO(agreement?.updated_at),
          "LLLL do, yyyy"
        );
        return (
          <tr
            key={agreement?.event.name.concat(i)}
            className="cursor-pointer"
            onClick={(e) => navigateToAgreement(e, agreement)}
          >
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <span className="flex items-center truncate space-x-3 has-tooltip">
                <span className="font-medium truncate text-sm leading-6">
                  {agreement?.event.name}{" "}
                  <span className="truncate font-normal text-gray-500">
                    in{" "}
                    {agreement?.event.location || "agreement location not set"}
                  </span>
                </span>
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {agreement.counterparty.first_name}{" "}
              {agreement.counterparty.last_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {agreement?.role?.role_name || "agreement role not set"}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {agreement.fixed_rate_offered}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {formattedDate}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {actions && (
                <button className="h-5 w-5" aria-hidden="true">
                  <ActionMenu item={agreement} {...props} />
                </button>
              )}
            </td>
          </tr>
        );
      })}
    </ListViewContainer>
  );
};

export default ListView;
