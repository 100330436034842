import { parseISO, format } from "date-fns";
import ActionMenu from "./ActionMenu";
import ListViewContainer from "../../../components/ListView";
import { useHistory } from "react-router-dom";

const ListView = ({ invoices, loading, actions, ...props }) => {
  const history = useHistory();
  if (!invoices) {
    return <div>Loading...</div>;
  }

  const navigateToInvoice = (e, invoice) => {
    if (e.target.tagName === "svg" || e.target.tagName === "path") {
      return;
    }
    history.push(`/invoices/detail/${invoice?.uuid}`);
  };

  const fields = [
    {
      label: "Event",
      name: "event",
    },
    {
      label: "Person",
      name: "person",
    },
    {
      label: "Role",
      name: "role",
    },
    {
      label: "Total Amount",
      name: "amount",
    },
    {
      label: "Last Updated",
      name: "last-updated",
    },
  ];

  return (
    <ListViewContainer
      collection={invoices}
      fields={fields}
      loading={loading}
      actions={actions}
      {...props}
    >
      {invoices.map((invoice, i) => {
        const formattedDate = format(
          parseISO(invoice?.updated_at),
          "LLLL do, yyyy"
        );
        return (
          <tr
            key={invoice?.event?.name?.concat(i)}
            className="cursor-pointer"
            onClick={(e) => navigateToInvoice(e, invoice)}
          >
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <span className="flex items-center truncate space-x-3 has-tooltip">
                <span className="font-medium truncate text-sm leading-6">
                  {invoice?.event?.name}{" "}
                  <span className="truncate font-normal text-gray-500">
                    in {invoice?.event?.location || "invoice location not set"}
                  </span>
                </span>
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {invoice?.user?.first_name} {invoice?.user?.last_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {invoice?.role?.role_name || "invoice role not set"}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              $ {invoice.total_amount}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {formattedDate}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {actions && (
                <button className="h-5 w-5" aria-hidden="true">
                  <ActionMenu item={invoice} {...props} />
                </button>
              )}
            </td>
          </tr>
        );
      })}
    </ListViewContainer>
  );
};

export default ListView;
