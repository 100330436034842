import { convertISOStringToFormattedDateTime, convertISOStringToFormattedDateToTimeOnly } from "../../../../utils/time";

export default function Event(props) {
    const {loading, data } = props;
    if (!data) return <div>Loading</div>
    const { event } = data;
    return (
        <section aria-labelledby="event-information-title">
          <div className={`bg-white shadow sm:rounded-lg ${loading && 'animate-pulse'}`}>
            <div className="flex">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Event Information - {event.name ?? 'Event name not provided'}
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {event.location ?? 'Event location not provided'}
                </p>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.address ?? 'Event address not provided'}
                  </dd>
                  {event.address2 !== null && (
                    <dd className="mt-1 text-sm text-gray-900">
                      {event.address2}
                    </dd>
                  )}
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Date</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.date ? convertISOStringToFormattedDateTime(event.date) : 'Event date not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Call Time
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    { event.call_datetime ? convertISOStringToFormattedDateToTimeOnly(event.call_datetime) : 'Event call time not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Facilities
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.facilities ?? 'Event facilities not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Parking</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.parking ?? 'Event parking not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Credentials
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.credentials ?? 'Event credentials not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Transmission
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.transmission_datetime ? convertISOStringToFormattedDateToTimeOnly(event.transmission_datetime ) : 'Event transmission time not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Transmission Company
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.transmission_company ?? 'Event transmission company not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Channel Assignment
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.channel_assignment}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Budget</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.budget ?? 'Event budget not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">Notes</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {event.notes ?? 'Event notes not provided'}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </section>
    )
}