const axios = require('axios').default;

const instance = axios.create({})
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.baseURL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}`
const createHeaders = (AUTH_TOKEN) => {
    instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
}

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        localStorage.removeItem('access_token')
        window.location = '/login';
    } else {
        return Promise.reject(error);
    }
});

export { instance, createHeaders };
