const DefaultEventState = {
    location: null,
    name: null,
    address: null,
    address_line_2: null,
    date: null,
    call_datetime: null,
    facilities: null,
    parking: null,
    credentials: null,
    ready_datetime: null,
    transmission_datetime: null,
    transmission_company: null,
    uuid: null,
    notes: null,
    budget: null,
    channel_assignment: null,
};

export default DefaultEventState;
