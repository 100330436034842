import { useSelector } from "react-redux";
import { Formik } from "formik";
import ImageUploader from "react-images-upload";
import { useEffect, useState } from "react";
import { useGetUserByIdQuery, useUpdateUserProfileByUserUUIDMutation } from "../../services/user";
import { getUserDetails } from "../Login/slice";
import { useHistory } from "react-router-dom";

export default function Profile() {
  const user = useSelector(getUserDetails);
  const history = useHistory();
  const [profilePhotoLocal, setProfilePhotoLocal] = useState([]);
  const {isLoading, isError, error, data,} = useGetUserByIdQuery({uuid :user.uuid});
  const [localValues, setLocalValues] = useState({ ...data });
  const [
    updateUser,
    {
      isLoading: currentDataLoading,
      error: currentDataError,
      data: currentData,
    },
  ] = useUpdateUserProfileByUserUUIDMutation();

  const onSubmitHandler = async (values) => {
    const valuesCopy = { ...values };
    const userData = new FormData();

    userData.append("id", valuesCopy.uuid);
    userData.append("uuid", valuesCopy.uuid);
    userData.append("first_name", valuesCopy.first_name);
    userData.append("last_name", valuesCopy.last_name);
    userData.append("address", valuesCopy.address);
    userData.append("city", valuesCopy.city);
    userData.append("email", valuesCopy.email);
    userData.append("state", valuesCopy.state);

    userData.append("postal_code", valuesCopy.postal_code);

    if (profilePhotoLocal.length) {
      userData.append("photo", profilePhotoLocal[0] as any);
    }
    if (valuesCopy.country) {
      userData.append("country", valuesCopy.country);
    }
    setLocalValues({...valuesCopy})
    const res = await updateUser(userData);
    if ("data" in res) {
      let resCopy = res as any;
      setLocalValues(resCopy.user);
    }
  };

  useEffect(() => {
    setLocalValues(data)
  }, [data])

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setProfilePhotoLocal(pictureFiles);
  };

  const goBack = () => {
    history.push('/')
  }

  return (
    <Formik
      initialValues={localValues}
      onSubmit={onSubmitHandler}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        submitForm,
        isSubmitting,
        ...props
        /* and other goodies */
      }) => (
        <>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Profile
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    This information will be displayed within the organization.
                  </p>
                </div>

                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="photo"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Photo
                    {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Share a photo so your team can recognize you.
                    </p> */}
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="flex items-center">
                        {/* <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span> */}
                        <img src={values?.photo_url} alt="profile" className="h-24 w-24 rounded-full overflow-hidden bg-gray-100" />
                        <ImageUploader
                          className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                          buttonText="Choose"
                          singleImage={true}
                          withPreview={true}
                          onChange={onDrop}
                          buttonClassName="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                          maxFileSize={5242880}
                        />
                        {/* <button
                      type="button"
                      className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                    >
                      Change
                    </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Personal Information
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      First name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="first_name"
                        onChange={handleChange}
                        value={values?.first_name}
                        id="first_name"
                        autoComplete="first-name"
                        className="max-w-lg block w-full shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Last name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="last_name"
                        onChange={handleChange}
                        value={values?.last_name}
                        id="last_name"
                        autoComplete="family-name"
                        className="max-w-lg block w-full shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Email address
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        id="email"
                        name="email"
                        onChange={handleChange}
                        value={values?.email}
                        required
                        type="email"
                        autoComplete="email"
                        className="block max-w-lg w-full shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Phone Number
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="phone"
                        onChange={handleChange}
                        value={values?.phone}
                        id="phone"
                        autoComplete="phone"
                        className="max-w-lg block w-full shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Country / Region
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        id="country"
                        name="country"
                        onChange={handleChange}
                        value={values?.country || 'United States'}
                        autoComplete="country"
                        className="max-w-lg block focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="United States">United States</option>
                        <option value="Canada">Canada</option>
                        <option value="Mexico">Mexico</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Street address
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={values?.address}
                        name="address"
                        id="address"
                        autoComplete="address"
                        className="block max-w-lg w-full shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      City
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={values?.city}
                        name="city"
                        id="city"
                        className="max-w-lg block w-full shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      State / Province
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={values?.state}
                        name="state"
                        id="state"
                        className="max-w-lg block w-full shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="postal_code"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      ZIP / Postal
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={values?.postal_code}
                        name="postal_code"
                        id="postal_code"
                        autoComplete="postal-code"
                        className="max-w-lg block w-full shadow-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Notifications
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    We'll always let you know about important changes, but you
                    pick what else you want to hear about.
                  </p>
                </div>
                <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                  <div className="pt-6 sm:pt-5">
                    <div role="group" aria-labelledby="label-email">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-email"
                          >
                            By Email
                          </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:col-span-2">
                          <div className="max-w-lg space-y-4">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="comments"
                                  name="comments"
                                  type="checkbox"
                                  className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="comments"
                                  className="font-medium text-gray-700"
                                >
                                  Comments
                                </label>
                                <p className="text-gray-500">
                                  Get notified when someones posts a comment on
                                  an event, review or agreement.
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                  <input
                                    id="offers"
                                    name="offers"
                                    type="checkbox"
                                    className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300 rounded"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="offers"
                                    className="font-medium text-gray-700"
                                  >
                                    Offers
                                  </label>
                                  <p className="text-gray-500">
                                    Get notified when a candidate accepts or
                                    rejects an offer.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-6 sm:pt-5">
                    <div role="group" aria-labelledby="label-notifications">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-notifications"
                          >
                            Push Notifications
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="max-w-lg">
                            <p className="text-sm text-gray-500">
                              These are delivered via SMS to your mobile phone.
                            </p>
                            <div className="mt-4 space-y-4">
                              <div className="flex items-center">
                                <input
                                  id="push-everything"
                                  name="push-notifications"
                                  type="radio"
                                  className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300"
                                />
                                <label
                                  htmlFor="push-everything"
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  Everything
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="push-email"
                                  name="push-notifications"
                                  type="radio"
                                  className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300"
                                />
                                <label
                                  htmlFor="push-email"
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  Same as email
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="push-nothing"
                                  name="push-notifications"
                                  type="radio"
                                  className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300"
                                />
                                <label
                                  htmlFor="push-nothing"
                                  className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                  No push notifications
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => goBack()}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    onSubmitHandler(values);
                  }}
                  className={`${
                    currentDataLoading && "animate-pulse"
                  } ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500`}
                >
                  {currentDataLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
}
