import { TrashIcon } from '@heroicons/react/solid'

export default function PlainStackedList(props) {
  const { collection, location, removeItemFrom, setter } = props;
  if (!collection) return <div>Loading...</div>
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {collection?.map((configuration, index, collection) => (
          <li key={configuration?.name}>
            <a className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-gemstoneblue-600 truncate">{configuration?.name}</p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                  <button>
                    <TrashIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      onClick={(e) => {removeItemFrom(location, configuration, collection, setter)}}
                    />
                  </button>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
