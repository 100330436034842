import DateTimePicker from 'react-datetime-picker';
import './style.css'

export default function EventCont(props) {
    const { eventInfoContinued, setField, eventConfig, scrollTo, setFieldByName, values, errors, onChange, touched, setFieldValue } = props;

    return (
        <div>
          <div
            ref={eventInfoContinued}
            className="shadow sm:rounded-md"
          >
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Event Information Continued
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Event specifics.
                </p>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="event_date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Date
                  </label>
                  <DateTimePicker
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                    onChange={e => setFieldValue('event_date', e)}
                    disableClock={true}
                    value={values.event_date}
                    required
                    name="event_date"
                    id="event_date"
                    autoComplete="event-date"
                  />
                  {/* <input
                    type="text"
                    value={formQuestions.event_date}
                    onChange={onChange}
                    name="event_date"
                    id="event_date"
                    autoComplete="event-date"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  /> */}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="event_call_datetime"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Call Time
                  </label>
                  <DateTimePicker
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                    onChange={e => setFieldValue('event_call_datetime', e)}
                    disableClock={true}
                    value={values.event_call_datetime}
                    required
                    name="event_call_datetime"
                    id="event_call_datetime"
                    autoComplete="event-call-datetime"
                  />
                  {/* <input
                    type="text"
                    value={formQuestions.event_call_datetime}
                    onChange={onChange}
                    name="event_call_datetime"
                    id="event_call_datetime"
                    autoComplete="event-call-datetime"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  /> */}
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="event_facilities"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Facilities
                  </label>
                  <input
                    type="text"
                    value={values.event_facilities}
                    onChange={onChange}
                    required
                    name="event_facilities"
                    id="event_facilities"
                    autoComplete="event_facilities"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                  {errors.event_facilities && touched.event_facilities &&
                    <p className="mt-2 text-sm text-red-600" id="description-error">
                      This field is required.
                    </p>
                    }
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="event_parking"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Parking
                  </label>
                  <input
                    type="text"
                    value={values.event_parking}
                    onChange={onChange}
                    required
                    name="event_parking"
                    id="event_parking"
                    autoComplete="event_parking"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                  {errors.event_parking && touched.event_parking &&
                    <p className="mt-2 text-sm text-red-600" id="description-error">
                      This field is required.
                    </p>
                    }
                </div>

                {/* <div className="col-span-6">
                  <label
                    htmlFor="event_schedule"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Schedule
                  </label>
                  <input
                    type="text"
                    name="event_schedule"
                    value={values.event_schedule}
                    onChange={onChange}
                    id="event_schedule"
                    autoComplete="event-schedule"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div> */}

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="event_credentials"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Credentials
                  </label>
                  <input
                    type="text"
                    value={values.event_credentials}
                    onChange={onChange}
                    name="event_credentials"
                    id="event_credentials"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="event_notes"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Notes
                  </label>
                  <input
                    type="text"
                    value={values.event_notes}
                    onChange={onChange}
                    name="event_notes"
                    id="event_notes"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="event_transmission_datetime"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Transmission Time
                  </label>
                  <DateTimePicker
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                    onChange={e => setFieldValue('event_transmission_datetime', e)}
                    required
                    disableClock={true}
                    value={values.event_transmission_datetime}
                    name="event_transmission_datetime"
                    id="event_transmission_datetime"
                    autoComplete="event-transmission-datetime"
                  />
                  {/* <input
                    type="text"
                    value={values.event_transmission_datetime}
                    onChange={onChange}
                    name="event_transmission_datetime"
                    id="event_transmission_datetime"
                    autoComplete="event-transmission-datetime"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  /> */}
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="event_channel_assignment"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Channel Assignment
                  </label>
                  <input
                    type="text"
                    value={values.event_channel_assignment}
                    onChange={onChange}
                    name="event_channel_assignment"
                    id="event_channel_assignment"
                    autoComplete="event-channel-assigment"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="event_transmission_company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Transmission Company
                  </label>
                  <input
                    type="text"
                    value={values.event_transmission_company}
                    onChange={onChange}
                    name="event_transmission_company"
                    id="event_transmission_company"
                    autoComplete="event-transmission-company"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="event_source"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Event Source
                  </label>
                  <input
                    type="text"
                    value={values.event_source}
                    onChange={onChange}
                    name="event_source"
                    id="event_source"
                    autoComplete="event-source"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="event_ready_datetime"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Ready Time
                  </label>
                  <DateTimePicker
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                    onChange={(e) => {setFieldValue('event_ready_datetime', e)}}
                    value={values.event_ready_datetime}
                    required
                    name="event_ready_datetime"
                    id="event_ready_datetime"
                    disableClock={true}
                    autoComplete="event-ready-datetime"
                  />
                  {/* <input
                    type="text"
                    value={values.event_ready_datetime}
                    onChange={onChange}
                    name="event_ready_datetime"
                    id="event_ready_datetime"
                    autoComplete="event-ready-datetime"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  /> */}
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="event_budget"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Budget
                  </label>
                  <input
                    type="text"
                    value={values.event_budget}
                    onChange={onChange}
                    name="event_budget"
                    id="event_budget"
                    autoComplete="event-budget"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                onClick={() => {
                  scrollTo(eventConfig);
                }}
                // type="submit"
                className="bg-white-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gemstoneblue hover:bg-gemstoneroyal hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
              >
                Next
              </button>
            </div>
          </div>
        </div>
    )
}