// import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TeamList from './List';
import CreateTeam from './Create';
import TeamDetails from './Details';

export default function Teams() {
  let { url } = useRouteMatch();
  // const history = useHistory();
  return (
    <div className="h-screen">
      <Switch>
        <Route exact path={`${url}/`} component={TeamList} />
        <Route exact path={`${url}/create`} component={CreateTeam} />
        <Route exact path={`${url}/detail/:uuid`} component={TeamDetails} />
      </Switch>
    </div>
  )
};