import EventList from '../Events/List';

export default function Dashboard() {
  return (
    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <h3>Upcoming events</h3>
      <EventList dashboard={true} detailURLTarget={'/events'} />
    </div>
  )
}
