import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import DateTimePicker from 'react-datetime-picker';
import { XIcon } from "@heroicons/react/outline";
// import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { Formik } from "formik";
import { convertISOStringToDate  } from "../../../../utils/time";

export default function EditEventPanel(props) {
  const {
    validateFields,
    onSubmitHandler,
    loading,
    setLoading,
    history,
    axios,
    dispatch,
    open,
    setOpen,
    values,
  } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <Formik
                  initialValues={values}
                  enableReinitialize={true}
                  validate={(values) => validateFields(values)}
                  onSubmit={onSubmitHandler}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    submitForm,
                    isSubmitting,
                    isValid,
                    ...props
                    /* and other goodies */
                  }) => (
                    <>
                      <form
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
                      >
                        <div className="flex-1">
                          {/* Header */}
                          <div className="px-4 py-6 bg-gray-50 sm:px-6">
                            <div className="flex items-start justify-between space-x-3">
                              <div className="space-y-1">
                                <Dialog.Title className="text-lg font-medium text-gray-900">
                                  Edit Event
                                </Dialog.Title>
                                <p className="text-sm text-gray-500">
                                  Update the event info below. Press the update
                                  button at the bottom when you're satisfied
                                  with the changes.
                                </p>
                              </div>
                              <div className="h-7 flex items-center">
                                <button
                                  type="button"
                                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gemstoneblue-500"
                                  onClick={() => setOpen(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Divider container */}
                          <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            {/* event name */}
                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Name
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            {/* event description */}
                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Description
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <textarea
                                  id="description"
                                  name="description"
                                  value={values.description}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  rows={3}
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border border-gray-300 rounded-md"
                                  defaultValue={""}
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="address"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Address/Venue
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  value={values.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="address"
                                  id="address"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="date"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Date
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                {/* <input
                                  type="text"
                                  value={values.date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="date"
                                  id="date"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                /> */}
                                <DateTimePicker
                                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                                  onChange={e => setFieldValue('date', e)}
                                  required
                                  disableClock={true}
                                  value={convertISOStringToDate(values.date)}
                                  name="date"
                                  id="date"
                                  autoComplete="date"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="call_datetime"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Call Time
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                {/* <input
                                  type="text"
                                  value={values.call_datetime}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="call_datetime"
                                  id="call_datetime"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                /> */}
                                <DateTimePicker
                                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                                  onChange={e => setFieldValue('call_datetime', e)}
                                  required
                                  disableClock={true}
                                  value={convertISOStringToDate(values.call_datetime)}
                                  name="call_datetime"
                                  id="call_datetime"
                                  autoComplete="call-datetime"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="ready_datetime"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Ready Time
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                {/* <input
                                  type="text"
                                  value={values.call_datetime}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="call_datetime"
                                  id="call_datetime"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                /> */}
                                <DateTimePicker
                                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                                  onChange={e => setFieldValue('ready_datetime', e)}
                                  required
                                  disableClock={true}
                                  value={convertISOStringToDate(values.ready_datetime)}
                                  name="ready_datetime"
                                  id="ready_datetime"
                                  autoComplete="ready-datetime"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="facilities"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Facilities
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="facilities"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.facilities}
                                  id="facilities"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="parking"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Parking
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  value={values.parking}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="parking"
                                  id="parking"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="credentials"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Credentials
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  value={values.credentials}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="credentials"
                                  id="credentials"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="transmission_datetime"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Transmission Time
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                {/* <input
                                  type="text"
                                  value={values.transmission_datetime}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="transmission_datetime"
                                  id="transmission_datetime"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                /> */}
                                <DateTimePicker
                                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
                                  onChange={e => setFieldValue('transmission_datetime', e)}
                                  required
                                  value={convertISOStringToDate(values.transmission_datetime)}
                                  name="transmission_datetime"
                                  id="transmission_datetime"
                                  autoComplete="transmission-datetime"
                                  disableClock={true}
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="transmission_company"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Transmission Company
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  value={values.transmission_company}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="transmission_company"
                                  id="transmission_company"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="channel_assignment"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Channel Assignment
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  value={values.channel_assignment}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="channel_assignment"
                                  id="channel_assignment"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="budget"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Budget
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  value={values.budget}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="budget"
                                  id="budget"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="notes"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Notes
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  value={values.notes}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="notes"
                                  id="notes"
                                  className="block w-full shadow-sm sm:text-sm focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            {/* Team members */}
                            {/* <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                            <div>
                              <h3 className="text-sm font-medium text-gray-900">Event Staff</h3>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="flex space-x-2">
                                {team.map((person) => (
                                  <a
                                    key={person.email}
                                    href={person.href}
                                    className="flex-shrink-0 rounded-full hover:opacity-75"
                                  >
                                    <img
                                      className="inline-block h-8 w-8 rounded-full"
                                      src={person.imageUrl}
                                      alt={person.name}
                                    />
                                  </a>
                                ))}

                                <button
                                  type="button"
                                  className="flex-shrink-0 bg-white inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                                >
                                  <span className="sr-only">Add event staff</span>
                                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div> */}

                            {/* Privacy */}
                            {/* <fieldset>
                            <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-5">
                              <div>
                                <legend className="text-sm font-medium text-gray-900">Privacy</legend>
                              </div>
                              <div className="space-y-5 sm:col-span-2">
                                <div className="space-y-5 sm:mt-0">
                                  <div className="relative flex items-start">
                                    <div className="absolute flex items-center h-5">
                                      <input
                                        id="public-access"
                                        name="privacy"
                                        aria-describedby="public-access-description"
                                        type="radio"
                                        className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300"
                                        defaultChecked
                                      />
                                    </div>
                                    <div className="pl-7 text-sm">
                                      <label htmlFor="public-access" className="font-medium text-gray-900">
                                        Public access
                                      </label>
                                      <p id="public-access-description" className="text-gray-500">
                                        Everyone with the link will see this event
                                      </p>
                                    </div>
                                  </div>
                                  <div className="relative flex items-start">
                                    <div className="absolute flex items-center h-5">
                                      <input
                                        id="restricted-access"
                                        name="privacy"
                                        aria-describedby="restricted-access-description"
                                        type="radio"
                                        className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300"
                                      />
                                    </div>
                                    <div className="pl-7 text-sm">
                                      <label htmlFor="restricted-access" className="font-medium text-gray-900">
                                        Private to event Members
                                      </label>
                                      <p id="restricted-access-description" className="text-gray-500">
                                        Only members of this event would be able to access
                                      </p>
                                    </div>
                                  </div>
                                  <div className="relative flex items-start">
                                    <div className="absolute flex items-center h-5">
                                      <input
                                        id="private-access"
                                        name="privacy"
                                        aria-describedby="private-access-description"
                                        type="radio"
                                        className="focus:ring-gemstoneblue-500 h-4 w-4 text-gemstoneblue-600 border-gray-300"
                                      />
                                    </div>
                                    <div className="pl-7 text-sm">
                                      <label htmlFor="private-access" className="font-medium text-gray-900">
                                        Private to you
                                      </label>
                                      <p id="private-access-description" className="text-gray-500">
                                        You are the only one able to access this event
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <hr className="border-gray-200" />
                                <div className="flex flex-col space-between space-y-4 sm:flex-row sm:items-center sm:space-between sm:space-y-0">
                                  <div className="flex-1">
                                    <a
                                      href="#"
                                      className="group flex items-center text-sm text-gemstoneblue-600 hover:text-gemstoneblue-900 font-medium space-x-2.5"
                                    >
                                      <LinkIcon
                                        className="h-5 w-5 text-gemstoneblue-500 group-hover:text-gemstoneblue-900"
                                        aria-hidden="true"
                                      />
                                      <span>Copy link</span>
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      href="#"
                                      className="group flex items-center text-sm text-gray-500 hover:text-gray-900 space-x-2.5"
                                    >
                                      <QuestionMarkCircleIcon
                                        className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      <span>Learn more about sharing</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset> */}
                          </div>
                        </div>

                        {/* Action buttons */}
                        <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                          <div className="space-x-3 flex justify-end">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                              onClick={() => setOpen(false)}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => onSubmitHandler(values, isValid)}
                              type="submit"
                              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
                            >
                              {`${loading ? "Updating..." : "Update"}`}
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
