import React, { Fragment } from 'react';
import { Disclosure } from '@headlessui/react'

export default function NotFound() {
    return (
        <div className="min-h-screen bg-gray-100">
            <div className="bg-gemstoneblue pb-32">
                <Disclosure as="nav" className="bg-gemstoneblue border-b border-gemstoneroyal-300 border-opacity-25 lg:border-none">

                </Disclosure>
                <header className="py-10">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-white">Page not found</h1>
                    </div>
                </header>
            </div>
            <main className="-mt-32">
            <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                {/* Replace with your content */}
                <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 h-96">
                    The page you are looking for has moved or has been terminated.
                </div>
                {/* /End replace */}
                </div>
            </main>
        </div>
    )
}