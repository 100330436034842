import { instance } from "../../../utils/axios";
import { useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNotification,
  showFailure,
  showSuccess,
} from "../../Notification/slice";
import ListView from './ListView';
import Tabs from "./Tabs";
import { getUserDetails } from "../../Login/slice";

const EventList = ({ dashboard, detailURLTarget }) => {
  const axios = instance;
  let { url } = useRouteMatch();
  const [activeEvents, setActiveEvents] = useState([]);
  const [completedEvents, setCompletedEvents] = useState([]);
  const [text, setText] = useState("");
  const [primaryText, setPrimaryText] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const user = useSelector(getUserDetails);
  const [tabs, setTabs] = useState([
    { name: 'Active', href: '#', current: true },
    { name: 'Completed', href: '#', current: false },
  ])

  const handleDelete = async (event) => {
    setCurrentEvent(event);
    setText("Are you sure you want to delete this Event?");
    setPrimaryText("Delete Event");
    setButtonText("Delete");
    setOpen(true);
  };

  const handleCancel = async (event) => {
    setCurrentEvent(event);
    setText(
      "Are you sure you want to cancel this Event? This will notify all assigned staff of the cancelation."
    );
    setPrimaryText("Cancel Event");
    setButtonText("Cancel Event");
    setOpenCancel(true);
  };

  const cancelEvent = async (e, event) => {
    // Cancel the event here. Set status and send notifications.
    setLoading(true);
    const uuid = event?.uuid;
    dispatch(handleNotification());
    /**
     * The cancel route needs to:
     * 1. Set the event status to canceled.
     * 2. Notify all users of cancelation via text.
     */
    const URL = `/api/events/${uuid}/cancel`;
    const response = await axios.post(URL);
    if (response.status === 200) {
      dispatch(
        showSuccess({
          heading: "Success",
          message: response.data.message,
          status: response.data.updated,
        })
      );
    } else {
      dispatch(
        showFailure({
          heading: "Failure",
          message: response.data.message,
          status: response.data.updated,
        })
      );
    }
    setLoading(false);
    setOpenCancel(false);
  };

  const deleteEvent = async (e, event) => {
    setLoading(true);
    const uuid = event.uuid;
    dispatch(handleNotification());
    const remaining = activeEvents.filter((id) => {
      if (id.uuid === event.uuid) {
        const URL = `/api/events/${uuid}`;
        try {
          const response = axios.delete(URL);
          response.then((data) => {
            dispatch(
              showSuccess({
                heading: "Success",
                message: data.data.message,
                status: true,
              })
            );
          });
        } catch (e) {
          dispatch(
            showFailure({
              heading: "Failure!",
              message: "Something went wrong. Please try again.",
              status: false,
            })
          );
        }
      }

      return id.uuid !== uuid;
    });
    setActiveEvents(remaining);
    setLoading(false);
    setOpen(false);
  };

  useMemo(() => {
    setLoading(true);
    const URL = `/api/events`;
    let config;
    let enddate;
    if (dashboard) {
      enddate = new Date(new Date().setDate(new Date().getDate() + 7))
      config = {
        params: {
          status: ["New", "Updated"],
          search_start_date: new Date().toISOString().substring(0, 10),
          search_end_date: `${enddate.getFullYear()}-${enddate.getMonth()+1}-${enddate.getDate()}`
        }
      }
    } else {
      config = {
        params: {
          status: ["New", "Updated"],
        },
      };
    }
    const configCompleted = {
      params: {
        status: ["Completed"],
      },
    };
    const eventActiveListRequest = axios.get(URL, config);
    const eventCompletedListRequest = axios.get(URL, configCompleted);
    try {
      eventActiveListRequest.then((eventListRequestResponse) => {
        setActiveEvents(eventListRequestResponse.data);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
    try {
      eventCompletedListRequest.then((eventListRequestResponse) => {
        setCompletedEvents(eventListRequestResponse.data);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const changeTabs = (e, name) => {
    let tabsCopy = tabs;
    const tabIndex = tabsCopy.findIndex((i) => i.name === name)
    // Future proof if we want more than one tab
    tabsCopy.forEach((t) => {
      t.current = false;
    })
    tabsCopy[tabIndex].current = true;
    setTabs(tabsCopy);
  }
  const active = tabs.find((i) => i.name === 'Active')
  return (
      <>
       {!dashboard && <Tabs
        tabs={tabs}
        onChange={changeTabs}
      />}
        <ListView
        events={active.current ? activeEvents : completedEvents}
        actions={true}
        user={user}
        loading={loading}
        url={dashboard ? detailURLTarget : url}
        handleDelete={handleDelete}
        handleCancelAction={cancelEvent}
        handleCancel={handleCancel}
      />
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        item={currentEvent}
        onClick={deleteEvent}
        text={text}
        primaryText={primaryText}
        buttonText={buttonText}
      />
      <ConfirmDialog
        open={openCancel}
        setOpen={setOpenCancel}
        item={currentEvent}
        onClick={cancelEvent}
        text={text}
        primaryText={primaryText}
        buttonText={buttonText}
      />
    </>
  );
}

export default EventList;
