import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import AvatarDropDown from '../../../components/AvatarDropDown';
import { showFailure, showSuccess } from "../../../containers/Notification/slice";
import { instance } from "../../../utils/axios";

export default function CreateTeam() {
    const history = useHistory();
    const dispatch = useDispatch();
    const axios = instance;
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [eventStaffAvailableData, setEventStaffAvailable] = useState([]);
    const [staff, setStaff] = useState([]);

    useEffect(() => {
      const URL = `/api/users`;
      axios.get(URL).then((response) => {
        let data = mapStaffToAvatarDropdownShape(response.data);
        setEventStaffAvailable(data);
      });
    }, []);

    const mapStaffToAvatarDropdownShape = (data) => {
      let result = [];
      for (let i = 0; i < data.length; i++) {
        result.push({user: { ...data[i]}})
      }
      return result;
    }

    const getMembers = () => {
      let members = [];
      staff.forEach((member) => {
        members.push(member.user.uuid)
      })
      return members;
    }

    const handleRemoveMemberFromCollection = (value) => {
      const membersCopy = [...staff];
      membersCopy.splice(membersCopy.indexOf(value), 1);
      setStaff(membersCopy);
    }

    const submit = async (e) => {
      e.preventDefault();
      try {
        const url = '/api/teams/create';
        const response = await axios.post(url, {
          name,
          description,
          members: getMembers()
        })
        if (response.status === 200) {
          dispatch(
            showSuccess({
              heading: "Success!",
              message: response.data.message,
              status: response.data.updated,
            })
          );
          history.push('/teams');
        }
      } catch (e) {
        dispatch(
          showFailure({
            heading: "Failure",
            message: 'Team creation failed. Please try again.'
          })
        );
        setLoading(false);
      }
    }

    // TODO figure out why i wanted people/person here
    const handleChange = (e, people, person) => {
      setSelected(e)
      setStaff([...staff, e]);
    }

    return (
      <form className={`space-y-8 divide-y divide-gray-200 ${loading && "animate-pulse"}`}>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Create Team</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Build a team with your favorite staff to work an event.
              </p>
            </div>
  
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="Name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="Name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      id="Name"
                      autoComplete="Name"
                      className="flex-1 block w-full focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
              </div>
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  About
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    className="max-w-lg shadow-sm block w-full focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm border border-gray-300 rounded-md"
                    defaultValue={''}
                  />
                  <p className="mt-2 text-sm text-gray-500">Describe the team.</p>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Members
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="mt-4 mb-4">
                    <div className="flex -space-x-1 relative z-0 overflow-hidden">
                      {staff?.map((member) => {
                        return (
                          <>
                          <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
                            <span className="text-xs font-medium leading-none text-white">{member?.user?.first_name[0]}{member?.user?.last_name[0]}</span>
                          </span>
                          <button
                          type="button"
                          className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gemstoneblue-400 hover:bg-gemstoneplaybook-200 hover:text-gemstoneblue-500 focus:outline-none focus:bg-gemstone-400 focus:text-white"
                        >
                          <span className="sr-only">
                            Remove member from team
                          </span>
                          <svg onClick={() => {handleRemoveMemberFromCollection(member)}} className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                        </>
                        )
                      })}
                    </div>
                  </div>
                  <AvatarDropDown
                    people={eventStaffAvailableData}
                    onChange={handleChange}
                    selected={selected}
                    setSelected={setSelected}
                    loadingText='Loading'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {history.push('/teams')}}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={e => submit(e)}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    )
  }
  