import { instance } from "../../../utils/axios";
import { useEffect, useState } from "react";
import CardWithAction from "../../../components/CardWithAction";
import SimpleDropdown from "../../../components/SimpleDropdown";
import Rate from "../../../components/Rate";
import { useParams } from "react-router-dom";
import {
  handleNotification,
  showFailure,
  showSuccess,
} from "../../../containers/Notification/slice";
import { useDispatch } from "react-redux";

type DetailsParams = {
  uuid: string;
}

export default function AgreementUpdate() {
  let { uuid } = useParams<DetailsParams>()
  if (!uuid) uuid = window.location.pathname.split("/")[2]
  const dispatch = useDispatch();

  const axios = instance;

  const [agreement, setAgreement] = useState({
    id: null,
    created_at: null,
    updated_at: null,
    tenant_id: 1,
    party_id: null,
    counterparty_id: null,
    sms_message_sid: null,
    event_id: null,
    party_event_role_id: null,
    terms: null,
    hourly_rate_offered: "",
    fixed_rate_offered: "",
    party_accept_datetime: null,
    counterparty_accept_datetime: null,
    party_decline_datetime: null,
    counterparty_decline_datetime: null,
    status: null,
    event: {
      name: null,
      date: null,
    },
    role: {
      uuid: null,
      role_name: null,
    },
  });

  const updateAgreement = () => {
    const UpdateURL = `/api/agreements/${uuid}/contractor`;
    dispatch(handleNotification());
    try {
      axios
        .put(UpdateURL, {
          status: agreement.status,
        })
        .then((response) => {
          dispatch(
            showSuccess({
              heading: "Success!",
              message: response.data.message,
              status: response.data.updated,
            })
          );
        });
    } catch (e) {
      dispatch(
        showFailure({
          heading: "Failure",
          message: "Something went wrong. Please try again",
          status: false,
        })
      );
    }
  };

  const updateStatus = (event) => {
    // Non-mutating copy of react state
    const agreementCopy = { ...agreement };
    agreementCopy.status = event.target.value;
    setAgreement(agreementCopy);
  };
  useEffect(() => {
    const URL = `/api/agreements/${uuid}/full`;
    axios.get(URL).then((eventRequestResponse) => {
      const update = { ...eventRequestResponse.data };
      update.status = "accepted";
      setAgreement(update);
    });
  }, []);

  return (
    <CardWithAction
      heading={`${agreement.event.name} - ${agreement.event.date}`}
      handler={updateAgreement}
      button="Submit"
      allowedAction={true}
      content={
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Role</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {agreement.role.role_name}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Offer Status
              </dt>

              <dd className="mt-1 text-sm text-gray-900">
                <SimpleDropdown
                  options={[
                    { name: "accepted", selected: true, value: "accepted" },
                    { name: "declined", value: "declined" },
                  ]}
                  onChange={updateStatus}
                ></SimpleDropdown>
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Rate Offered
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {agreement.fixed_rate_offered ||
                agreement.hourly_rate_offered ? (
                  <Rate
                    fixed={agreement.fixed_rate_offered}
                    hourly={agreement.hourly_rate_offered}
                  />
                ) : null}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Terms</dt>
              <dd className="mt-1 text-sm text-gray-900">{agreement.terms}</dd>
            </div>
          </dl>
        </div>
      }
    />
  );
}
