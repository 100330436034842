import { toDate, format as formatTZ } from "date-fns-tz";

const makeArrayOfTime = (interval) => {
    const x = interval; //minutes interval
    const times = []; // time array
    let tt = 0; // start time
    const ap = ['AM', 'PM']; // AM-PM
    for (let i=0;tt<24*60; i++) {
        const hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        const mm = (tt%60); // getting minutes of the hour in 0-55 format
        times[i] = {id: i, name: ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]}; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }
    return times;
}

export const convertISOStringToFormattedDateTime = (isoString) => {
    const date = toDate(isoString);
    try {
        return formatTZ(date, 'LLLL do, yyyy hh:mmbb', { timeZone: 'America/New_York' });
    } catch (e) {
        return 'Date not available'
    }
}

export const convertISOStringToFormattedDateToTimeOnly = (isoString) => {
    const date = toDate(isoString);
    try {
        return formatTZ(date, 'hh:mmbb', { timeZone: 'America/New_York' });
    } catch (e) {
        return 'Date not available'
    }
}

export const convertISOStringToDate = (isoString) => {
    if (!isoString) {
        return null;
    }
    const date = toDate(isoString);
    try {
        return date;
    } catch (e) {
        return new Date();
    }
}

export default makeArrayOfTime;
