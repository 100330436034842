const RegistrationCodeField = ({setRegistrationCode, setIsCompany, registrationCode, error}) => (
    <div>
      <label
        htmlFor="registration-code"
        className="block text-sm font-medium text-gray-700"
      >
        Registration Code
      </label>
      <div className="mt-1 mb-8">
        <input
          id="registration-code"
          name="registration-code"
          type="registration-code"
          placeholder="Example: DH29H2D20G8A1JD30KA13FS9J2"
          value={registrationCode}
          onChange={(e) => {
            setRegistrationCode(e.target.value);
          }}
          autoComplete="registration-code"
          required
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm"
        />
      <div className="text-sm">
          <button type="button" onClick={(e) => {setIsCompany(true)}}>
            <a href="javascript: void()" className="font-medium text-gemstoneblue-600 hover:text-gemstoneblue-500">
              Don't have a registration code?
            </a>
          </button>
        </div>
      </div>
    <div>
      {error !== "" && (
        <div className="block text-sm font-medium text-red-700">
          {error}
        </div>
      )}
    </div>
  </div>
  )

export default RegistrationCodeField;
