import { Fragment, useState } from 'react';
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon } from '@heroicons/react/solid';
import { TrashIcon, ViewGridIcon, ClipboardCheckIcon, XCircleIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { hasRole } from '../../../utils/auth';
import { getUserDetails } from "../../Login/slice";
import { useSelector } from 'react-redux';

const ActionMenu = (props) => {
    const { handleDelete, event, url, handleCancel } = props;
    const [open, setOpen] = useState(false);
    const user = useSelector(getUserDetails);
    if (!event) {
        return <></>
    }
    return (
        <Disclosure
          as="nav"
          className="border-b border-gemstoneroyal-300 border-opacity-25 lg:border-none overflow:visible"
        >
            <MenuAlt2Icon onClick={() => {setOpen(!open)}} />
            <Menu as="div" className="ml-3 relative flex-shrink-0 z-100">
                    <>
                    <div>
                        <Menu.Button className="bg-gemstoneblue-600 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gemstoneroyal-600 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                            <MenuAlt2Icon />
                        </Menu.Button>
                    </div>
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            {hasRole(user, ['Tenant Admin']) && 
                            <Menu.Item>
                                <button
                                className="h-5 w-5"
                                aria-hidden="true"
                                onClick={() => {
                                    handleDelete(event)}}
                                >
                                    <TrashIcon color={"gray"} className={"hover:bg-gray-100"} />
                                </button>
                            </Menu.Item>}
                            <Menu.Item>
                                <button className="h-5 w-5" aria-hidden="true">
                                    <Link
                                        to={`${url}/detail/${event.uuid}`}
                                        className="text-gemstoneblue-600 hover:text-gemstoneblue-900"
                                    >
                                        <ViewGridIcon color={"gray"} className={"hover:bg-gray-100"} />
                                    </Link>
                                </button>
                            </Menu.Item>
                            { hasRole(user, ['Tenant Admin']) && new Date(event.date) < new Date() && <Menu.Item>
                                <button className="h-5 w-5" aria-hidden="true">
                                    <Link
                                        to={`${url}/review/${event.uuid}`}
                                        className="text-gemstoneblue-600 hover:text-gemstoneblue-900"
                                    >
                                        <ClipboardCheckIcon color={"gray"} className={"hover:bg-gray-100"} />
                                    </Link>
                                </button>
                            </Menu.Item>}
                            { hasRole(user, ['Tenant Admin']) && new Date(event.date) > new Date() && <Menu.Item>
                                <button
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                    onClick={() => {
                                        handleCancel(event)
                                    }}
                                    >
                                    <XCircleIcon color={"gray"} className={"hover:bg-gray-100"} />
                                </button>
                            </Menu.Item>}
                        </Menu.Items>
                    </Transition>
                    </>
                </Menu>
        </Disclosure>
    )
};

export default ActionMenu;
