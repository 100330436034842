import { parseISO, format } from "date-fns";
import classNames from "../../../utils/helpers";
import ActionMenu from "./ActionMenu";
import { useHistory } from "react-router-dom";

const ListView = ({ events, loading, actions, ...props }) => {
  const history = useHistory();
  if (!events) {
    return <div>Loading...</div>;
  }

  const navigateToEvent = (e, event) => {
    if (e.target.tagName === "svg" || e.target.tagName === "path") {
      return;
    }
    history.push(`/events/detail/${event?.uuid}`)
  }
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table
              className={`min-w-full divide-y divide-gray-200 ${
                loading && "animate-pulse"
              }`}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Event
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Event Crew
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Event Date and Time
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Updated
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {events?.map((event) => {
                  const formattedDate = format(
                    parseISO(event?.updated_at),
                    "LLLL do, yyyy"
                  );
                  let bgColor = "bg-red-500";
                  switch (event?.status) {
                    case "in progress":
                      bgColor = "bg-gemstoneplaybook-500";
                      break;

                    default:
                      break;
                  }
                  return (
                    <tr
                      key={event?.name}
                      className="cursor-pointer"
                      onClick={(e) =>
                        navigateToEvent(e, event)
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <span className="flex items-center truncate space-x-3 has-tooltip">
                          <span
                            className={classNames(
                              bgColor,
                              "w-2.5 h-2.5 flex-shrink-0 rounded-full"
                            )}
                            aria-hidden="true"
                          />
                          <span className="tooltip bg-gemstoneblue z-40 border border-gray-600 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500 -mt-8">
                            This event needs your attention.
                          </span>
                          <span className="font-medium truncate text-sm leading-6">
                            {event?.name}{" "}
                            <span className="truncate font-normal text-gray-500">
                              in {event?.location}
                            </span>
                          </span>
                        </span>
                      </td>
                      <td className="px-6 py-3 text-sm text-gray-500 font-medium">
                        <div className="flex items-center space-x-2">
                          <div className="flex flex-shrink-0 -space-x-1">
                            {event?.members &&
                              event?.members.map((member) => (
                                <img
                                  key={member?.handle}
                                  className="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
                                  src={member?.imageUrl}
                                  alt={member?.name}
                                />
                              ))}
                          </div>
                          {event?.totalMembers &&
                          event?.totalMembers > event?.members?.length ? (
                            <span className="flex-shrink-0 text-xs leading-5 font-medium">
                              +{event?.totalMembers - event?.members?.length}
                            </span>
                          ) : null}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {event?.date &&
                          `${format(parseISO(event?.date), "LLLL do, yyyy")}`}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formattedDate}
                      </td>
                      <td>
                        {actions && (
                          <button className="h-5 w-5" aria-hidden="true">
                            <ActionMenu event={event} {...props} />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListView;
