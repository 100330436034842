import { convertISOStringToFormattedDateTime } from "../../../../utils/time";

export default function AgreementInfo(props) {
    const { agreement, loading } = props;

    if (!agreement) return <div>Loading</div>
    const { counterparty } = agreement;
    return (
        <section aria-labelledby="agreement-information-title">
          <div className={`bg-white shadow sm:rounded-lg ${loading && 'animate-pulse'}`}>
            <div className="flex">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Agreement Information - {agreement.uuid ?? 'agreement id not provided'}
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Created {agreement.created_at ? convertISOStringToFormattedDateTime(agreement.created_at) : 'agreement date not provided'}
                </p>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Agreement Status</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {agreement.status}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Agreement Accepted/Declined Date</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {agreement.status === 'pending' ? 'Agreement pending' : agreement.status === 'accepted' ? convertISOStringToFormattedDateTime(agreement.accepted_at) : convertISOStringToFormattedDateTime(agreement.declined_at)}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Role Performed</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {agreement.role.role_name ?? 'Role not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Pay Rate Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {agreement.daily_rate_offered ? 'Daily' : null}
                    {agreement.fixed_rate_offered ? 'Fixed' : null}
                    {agreement.hourly_rate_offered ? 'Hourly': null}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Agreement Payee
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {counterparty.first_name} { counterparty.last_name}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Payee Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {counterparty.email ?? 'Email not provided'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Payee Location</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {counterparty.city}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Payee Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {counterparty.phone}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Total Hours</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {agreement.total_hours ?? 0}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Total Amount Paid
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    { agreement.total_amount ?? 'N/A'}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </section>
    )
}