const validateFields = (values) => {
    const errors = {
      event_name: null,
      event_description: null,
      event_contact_first_name: null,
      event_contact_last_name: null,
      event_contact_email_address: null,
      event_street_address: null,
      event_city: null,
      event_state: null,
      event_postal_code: null,
      event_date: null,
    };
    if (!errors.event_name || errors.event_name === "") {
      errors.event_name = "Required";
    }
    if (!errors.event_description) {
      errors.event_description = "Required";
    }
    if (!errors.event_contact_first_name) {
      errors.event_contact_first_name = "Required";
    }
    if (!errors.event_contact_email_address) {
      errors.event_contact_email_address = "Required";
    }
    if (!errors.event_street_address) {
      errors.event_street_address = "Required";
    }
    if (!errors.event_date) {
      errors.event_date = "Required";
    }
    if (!errors.event_city) {
      errors.event_city = "Required";
    }
    if (!errors.event_state) {
      errors.event_state = "Required";
    }
    if (!errors.event_postal_code) {
      errors.event_postal_code = "Required";
    }
    return errors;
  };

  export default validateFields;
  