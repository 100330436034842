import { Route, Switch, useRouteMatch } from "react-router-dom";
import InvoiceList from "./List";
import InvoiceDetails from "./Details";

export default function Invoices() {
  let { path, url } = useRouteMatch();
  return (
    <div className="h-screen">
      <Switch>
        <Route exact path={`${url}`} component={InvoiceList} />
        <Route exact path={`${url}/detail/:uuid`} component={InvoiceDetails} />
      </Switch>
    </div>
  );
}
