import React, { useState } from "react";
import { UsersIcon } from "@heroicons/react/solid";
import SimpleDropdown from "../SimpleDropdown";
import makeArrayOfTime from "../../utils/time";
/**
 * Populate dropdown for time picker.
 */
const times = makeArrayOfTime(15);

const eventConfigLayout = (props) => {
  const { collection, onClickHandler } = props;
  if (collection.length === 0) {
    return;
  }

  type EventConfig = {
    cost: string;
    created_at: string;
    id: number;
    name: string;
    status: string;
    tenant_id: number;
    type: string;
    updated_at: string;
  };

  return (
    <>
      {collection.map((position: EventConfig) => {
        return (
          <li
            key={position.id.toString()}
            onClick={(e) => {
              onClickHandler(e, position);
            }}
          >
            <a className="block hover:bg-gray-50 cursor-pointer">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gemstoneblue-600 truncate">
                    {position?.name}
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {position?.status}
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <UsersIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {position?.type}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        );
      })}
    </>
  );
};

export type Role = {
  created_at: string;
  default_role_rate: string;
  id: number;
  role_name: string;
  tenant_id: number;
  updated_at: string;
  uuid: string;
};

export type Staff = {
  address: string;
  address_line_2: string;
  city: string;
  created_at: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  postal_code: string;
  role_id: string;
  state: string;
  status: string;
  tenant_id: number;
  updated_at: string;
  user_id: string;
  uuid: string;
};

export type StaffRoleCollectionObject = {
  roles: Role[];
  staff: Staff[];
};

export const EventStaffLayout = (props: {
  collection: StaffRoleCollectionObject[];
  onClickHandler: Function;
  roles: any;
  onChangeHandler: Function;
}) => {
  const { collection, onClickHandler, roles, onChangeHandler } = props;
  const [currentRole, setCurrentRole] = useState({});
  if (!collection || collection?.length === 0) {
    return;
  }

  /**
   * Two kinds of collections.
   * 1. collection[0] merge
   *    - This comes from event create magic?
   * 2. collection.map (used by view details)
   *    - default behavior
   */

  return (
    <>
      {collection[0] &&
        collection[0].staff?.map((person) => {
          return (
            <li key={person.uuid}>
              <a className="block hover:bg-gray-50 cursor-pointer">
                {/* <div className="px-4 py-4 sm:px-6" onClick={(e) => {onClickHandler(e, person.uuid)}}> */}
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img className="h-10 w-10 rounded-full" alt="" />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {person.first_name} {person.last_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {person.email}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      {collection[0].roles && (
                        <>
                          <SimpleDropdown
                            data={person}
                            options={collection[0].roles}
                            onChange={onChangeHandler}
                          />
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      {/* <li onClick={() => {onClickHandler()}}>
        <a className="block hover:bg-gray-50 cursor-pointer">
          <div className="px-4 py-4 sm:px-6">
            <div className="flex items-center">
                <label className="text-gray-500">I want to assign this role later</label>
              </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">
                  <SimpleDropdown
                    options={collection[0].roles}
                  />
                </p>
              </div>
            </div> */}
      {/* <div className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">{person?.title}</div>
              <div className="text-sm text-gray-500">{person?.department}</div>
            </div> */}
      {/* </div>
        </a>
      </li> */}
    </>
  );
};

const renderedComponent = (type, props) => {
  switch (type) {
    case "eventConfiguration":
      return eventConfigLayout(props);
    case "eventStaff":
      return EventStaffLayout(props);
    default:
      break;
  }
};
export default function ModalList(props) {
  const { type } = props;

  return (
    <div
      className={`bg-white ${
        type === "eventMeeting" ? "" : "shadow"
      } sm:rounded-md`}
    >
      <ul className="divide-y divide-gray-200">
        {renderedComponent(type, props)}
      </ul>
    </div>
  );
}
