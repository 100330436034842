import { parseISO, format } from "date-fns";
import ActionMenu from "./ActionMenu";
import { useHistory } from "react-router-dom";

const ListView = ({ reviews, loading, actions, ...props }) => {
  const history = useHistory();
  if (!reviews) {
    return <div>Loading...</div>;
  }

  const navigateToReview = (e, review) => {
    if (e.target.tagName === "svg" || e.target.tagName === "path") {
      return;
    }
    history.push(`/reviews/detail/${review?.uuid}`);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table
              className={`min-w-full divide-y divide-gray-200 ${
                loading && "animate-pulse"
              }`}
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Event
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Person
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Member Rating
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Reviewer
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {reviews?.map((review, i) => {
                  const formattedDate = format(
                    parseISO(review?.updated_at),
                    "LLLL do, yyyy"
                  );
                  let bgColor = "bg-red-500";
                  switch (review?.status) {
                    case "in progress":
                      bgColor = "bg-gemstone-400";
                      break;

                    default:
                      break;
                  }
                  return (
                    <tr
                      key={review?.role_id.concat(i)}
                      className="cursor-pointer"
                      onClick={(e) => navigateToReview(e, review)}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <span className="flex items-center truncate space-x-3 has-tooltip">
                          <span className="font-medium truncate text-sm leading-6">
                            {review?.event.name}{" "}
                            <span className="truncate font-normal text-gray-500">
                              in{" "}
                              {review?.event?.location ||
                                "review location not set"}
                            </span>
                          </span>
                        </span>
                      </td>
                      <td className="px-6 py-3 text-sm text-gray-500 font-medium has-tooltip">
                        <div className="flex flex-shrink-0 -space-x-1">
                          <div className="mt-4 mb-4">
                            <div className="flex-space-x-1 relative z-0">
                              <span className="tooltip absolute px-4 py-4">
                                {review?.user?.first_name}{" "}
                                {review?.user?.last_name}
                              </span>
                              <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
                                <span className="text-xs font-medium leading-none text-white">
                                  {review?.user?.first_name[0]}
                                  {review?.user?.last_name[0]}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {review?.rating || "Unrated"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {review?.reviewer.first_name}{" "}
                        {review?.reviewer.last_name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {actions && (
                          <button className="h-5 w-5" aria-hidden="true">
                            <ActionMenu item={review} {...props} />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListView;
