import { createSlice } from "@reduxjs/toolkit";

interface NotificationState {
  show: boolean;
  heading: string;
  message: string;
  status: boolean; // Why would a status be boolean?
}

export const initialState: NotificationState = {
  show: false,
  heading: "",
  message: "",
  status: false,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    handleNotification(state) {
      return {
        ...state,
        show: true,
        heading: 'Submitting...',
        message: 'Your request is being submited...',
        status: true,
      };
    },
    showSuccess(state, action) {
      return {
        ...state,
        show: true,
        heading: action.payload.heading,
        message: action.payload.message,
        status: true,
      };
    },
    showFailure(state, action) {
      return {
        ...state,
        show: true,
        heading: action.payload.heading,
        message: action.payload.message,
        status: false,
      };
    },
    dismissNotification(state) {
      return {
        ...state,
        show: false,
        heading: "",
        message: "",
        status: false,
      };
    },
  },
});

export const isOpen = (state) => state.notifications.show;
export const notificationDetails = (state) => state.notifications;

export const { handleNotification, showSuccess, showFailure, dismissNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
