import { useParams } from "react-router-dom";
import { useGetAgreementByUUIDQuery } from "../../../services/laravel";
import EventInfo from "./EventInfo";
import AgreementInfo from './AgreementInfo';


export type DetailsParams = {
  uuid: string;
};

export default function Details() {
  let { uuid } = useParams<DetailsParams>();
  const { data, error, isLoading } = useGetAgreementByUUIDQuery(uuid);
  
  return (
    <div className="grid auto-rows-auto gap-y-10">
      <div className="grid grid-cols-2 gap-x-4 mb-6">
        {/* Main 2 column grid */}
        <EventInfo
          data={data}
          loading={isLoading}
        />
        <AgreementInfo
          agreement={data}
          loading={isLoading}
        />
    </div>
  </div>
  )
}
