import React, { useEffect } from 'react';
import {Link, useHistory } from 'react-router-dom';
import { createHeaders } from '../../utils/axios';
import { useAuth0 } from "@auth0/auth0-react";

export default function Authorize() {
    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    useEffect(() => {
      getAccessTokenSilently().then((response) => {
        if (response) {
          createHeaders(`Bearer ${response}`)
          history.push('/')
        }
      })
    }, [])
    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              Checking...
            </div>
            <Link to="/login">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    )
  }
  