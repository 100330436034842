import { useEffect, useState, useCallback } from "react";
import { instance } from "../../../utils/axios";
import { useHistory, useParams } from "react-router-dom";
import Feed from "../../../components/Feed";
import CardWithAction from "../../../components/CardWithAction";
import AvatarTable from "../../../components/AvatarTable";
import EventConfigList from "../../../components/EventConfigList";
import EventMeetingList from "../../../components/EventMeetingList";
import Modal from "../../../components/Modal";
import EventConfigurations from "../Create/configurations";
import EventInfo from "./EventInfo";
import { removeItemFrom } from "../index";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNotification,
  showFailure,
  showSuccess,
} from "../../Notification/slice";
import { UserEventRole } from "../../../components/AvatarTable";
import EditEventPanel from "./EditPanel";
import CommunicationModal from "../../../components/CommunicationModal";
import { useCreateEventMeetingByEventUUIDMutation, useCreateSMSbyEventUUIDMutation, useGetEventByUUIDQuery, useRemoveEventUserByEventUUIDandUserUUIDMutation, useUpdateEventByUUIDMutation } from "../../../services/laravel";
import DefaultEventState from "./eventstate";
import { handleCurrentEventUUID } from "../../../slices/events";
import EventMeetingLayout from "./EventMeetingModal";
import GenericModal from "../../../components/GenericModal";
import { getUserDetails } from "../../Login/slice";
import { hasRole } from "../../../utils/auth";
import EventMap from "../../../components/EventMap";

export type DetailsParams = {
  uuid: string;
};

export default function Details() {
  const axios = instance;
  const history = useHistory();
  const dispatch = useDispatch();
  const [event, setEvent] = useState(DefaultEventState);
  const [people, setPeople] = useState([]);
  const [commModalOpen, setCommModalOpen] = useState(false);
  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [eventMeta, setEventMeta] = useState([]);
  const [commMessage, setCommMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [meeting, setMeeting] = useState([]);
  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [isEditingDetails, setEditingDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalData, setModalData] = useState([]);
  const [eventConfigData, setEventConfigData] = useState([]);
  const [eventConfigOptionsData, setEventConfigOptionsData] =
    useState(EventConfigurations);
  const [eventRoleData, setEventRoleData] = useState([]);
  const user = useSelector(getUserDetails);
  const [eventStaffData, setEventStaffData] = useState([]);
  const [eventStaffAvailableData, setEventStaffAvailable] = useState(people);
  const [eventMeetingData, setEventMeetingData] = useState([]);
  let { uuid } = useParams<DetailsParams>();
  const { data, error, isLoading } = useGetEventByUUIDQuery(uuid);
  const [updateEventByUUID] = useUpdateEventByUUIDMutation();
  const [createSMSbyEventUUID,
  {
    data: createSMSbyEventUUIDData
  }] = useCreateSMSbyEventUUIDMutation();
  const [createEventMeetingByEventUUID,
    {
      data: createEventMeetingbyEventUUIDData
    }] = useCreateEventMeetingByEventUUIDMutation();
  const [removeMemberFromEventMeetingByEventUUIDandUserUUID,
    {
      data: removeMemberFromEventMeetingByEventUUIDandUserUUIDData
    }] = useRemoveEventUserByEventUUIDandUserUUIDMutation();

  useCallback(() => {
    dispatch(handleCurrentEventUUID(uuid));
  }, []);

  const popModalFor = (type) => {
    setModalContent(type);
    switch (type) {
      case "eventConfiguration":
        setModalData(eventConfigOptionsData);
        break;
      case "eventStaff":
        setModalData([
          { staff: eventStaffAvailableData, roles: eventRoleData },
        ]);
        break;
      case "eventMeeting":
        setModalData(eventMeetingData);
        break;
      default:
        break;
    }
    setOpen(true);
  };

  const removeItemNoticeDispatcher = (message, updated) => {
    dispatch(
      showSuccess({
        heading: "Success!",
        message: message,
        status: updated,
      })
    );
  };

  const handleCommunicationChange = (e) => {
    setCommMessage(e.target.value);
  };

  const handleCommunicationSubmit = async (e, message) => {
    e.preventDefault();
    setLoading(true);
    setCommModalOpen(false);
    const body = {
      message,
      event_uuid: event.uuid,
    };
    try {
      await createSMSbyEventUUID({uuid, body})
      setLoading(false);
      setCommModalOpen(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const cancelOffer = async (person: UserEventRole) => {
    const URL = `/api/agreements/${person.agreement_id}/cancel`;

    const body = {
      user_event_role_id: person.user_event_role_id,
      event_uuid: event.uuid,
    };
    try {
      const eventResponse = await axios.put(URL, {
        ...body,
      });

      if (eventResponse.status === 200) {
        const updatedPerson = people.find(
          (item) => item.user_event_role_id === person.user_event_role_id
        );
        if (updatedPerson) {
          updatedPerson.status = eventResponse.data.status;
        }

        const personIndex = people.findIndex(
          (p) => p.user.uuid === person.user.uuid
        );
        people.splice(personIndex - 1, personIndex);
        const peopleCopy = [...people, updatedPerson];

        setPeople(peopleCopy);
        dispatch(
          showSuccess({
            heading: "Success!",
            message: eventResponse.data.message,
            status: eventResponse.data.updated,
          })
        );
      }
    } catch (e) {
      dispatch(
        showFailure({
          heading: "Failure!",
          message: "Could not cancel agreement. Please try again later.",
          status: false,
        })
      );
    }
  };

  /**
   *
   * @param e Window Event
   * @param data Object from EventStaff
   * Create submission transformation
   * to build server facing schema. We
   * were using events but that went out
   * the window when we had mixed id/uuid
   * usage.
   */
  const handleAssignStaffRole = async (e, data) => {
    const peopleCopy = [...people];
    data.role_id = e.target.value;

    // Should we even mutate the eventStaffData?
    const roleIndex = eventRoleData.findIndex(
      (role) => role.uuid === data.role_id
    );
    const existingItem = people.findIndex(
      (staff) => staff.user.uuid === data.uuid
    );

    const person: UserEventRole = {
      user: {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        uuid: data.uuid,
        phone: data.phone,
        status: data.status,
      },
      event_role: {
        role_name: eventRoleData[roleIndex].role_name,
        uuid: eventRoleData[roleIndex].uuid,
      },
      status: null,
    };

    if (existingItem >= 0) {
      setLoading(true);
      const URL = `/api/user-event-roles/${people[existingItem].user_event_role_id}`;

      const body = {
        user_id: data.uuid,
        event_role_id: eventRoleData[roleIndex].uuid,
      };
      try {
        const eventResponse = await axios.put(URL, {
          ...body,
        });

        peopleCopy[existingItem] = person;
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    } else {
      setLoading(true);
      const URL = `/api/user-event-roles/`;

      const body = {
        user_id: data.uuid,
        event_role_id: eventRoleData[roleIndex].uuid,
        event_id: uuid,
      };
      try {
        const eventResponse = await axios.post(URL, {
          ...body,
        });

        peopleCopy.push(person);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }

    // Set EventStaffRoleData with both the person and role object
    setPeople([...peopleCopy]);
  };

  const validateFields = (values) => {
    const errors = {
      event_name: null,
      event_description: null,
      event_contact_first_name: null,
      event_contact_last_name: null,
      event_contact_email_address: null,
      event_street_address: null,
      event_city: null,
      event_state: null,
      event_postal_code: null,
      event_date: null,
    };
  };

  /**
   * Update event details panel
   */
  const onSubmitHandler = async (values, isValid) => {
    setLoading(true);
    const errors = validateFields(values);
    dispatch(handleNotification());
    const URL = `/api/events/${values.uuid}`;
    const body = {
      name: values.name,
      schedule: "?",
      status: "Updated",
      location: values.address,
      address: values.address,
      date: values.date,
      call_datetime: values.call_datetime,
      facilities: values.facilities,
      parking: values.parking,
      // schedule: values.event_schedule,
      credentials: values.credentials,
      notes: values.notes,
      transmission_datetime: values.transmission_datetime,
      channel_assignment: values.channel_assignment,
      transmission_company: values.transmission_company,
      source: values.event_source,
      ready_datetime: values.ready_datetime,
      additional_fields: "{}",
      budget: values.budget,
      created_by: "me",
    };
    try {
      // const eventResponse = await axios.put(URL, {
      //   ...body,
      // });
      const eventResponse = await updateEventByUUID({uuid: values.uuid, body});
      if ("data" in eventResponse) {
        dispatch(
          showSuccess({
            heading: "Success!",
            message: eventResponse.data.message,
            status: eventResponse.data.updated,
          })
        );
        setEditingDetails(false);
        setLoading(false);
        setEvent(eventResponse.data);
      }
    } catch (e) {
      dispatch(
        showFailure({
          heading: "Failure",
          message: "Something went wrong with your request. Please try again",
          status: false,
        })
      );
      setLoading(false);
    }
  };

  const handleSetEventConfigData = async (e, position) => {
    const URL = `/api/events/${uuid}/event-config`;
    try {
      const eventResponse = await axios.post(URL, {
        event_config_id: position.id,
      });

      if (eventResponse.status === 200) {
        if (eventConfigData.length) {
          const isThere = eventConfigData.filter((obj) => {
            // Comparing on name for now because we have some hardcoded values in the modal that are not coming from the db,
            // so the id's of the event config being filtered are not necessarily the id's in our databased
            return obj.name === position.name;
          });

          if (isThere.length === 0) {
            setEventConfigData([
              ...eventConfigData,
              eventConfigOptionsData[position.id],
            ]);
          }
          dispatch(
            showSuccess({
              heading: "Success!",
              message: eventResponse.data.message,
              status: eventResponse.data.updated,
            })
          );
          return;
        }
        dispatch(
          showSuccess({
            heading: "Success!",
            message: eventResponse.data.message,
            status: eventResponse.data.updated,
          })
        );

        setEventConfigData([eventConfigOptionsData[position.id]]);
      }
    } catch (e) {
      dispatch(
        showFailure({
          heading: "Failure!",
          message: "Could not add config option. Please try again later.",
          status: false,
        })
      );
    }
  };

  const handleRemoveMemberFromMeeting = async (value) => {
    const membersCopy = [...meetingParticipants];
    membersCopy.splice(membersCopy.indexOf(value), 1);
    removeMemberFromEventMeetingByEventUUIDandUserUUID({id: value.id})
    setMeetingParticipants(membersCopy);
  }

  const handleSetEventStaff = (data) => {
    const isThere = eventStaffData.filter((obj) => {
      return obj.id === 6;
    });

    if (isThere.length === 0) {
      setEventStaffData([...eventStaffData, eventStaffAvailableData[data]]);
    }
  };

  const handleSetEventMeeting = async (name, time, location, members) => {
    const flatMembers = [];
    members.forEach((member) => {
      flatMembers.push(member.user
      );
    });
    const body = {
      name: name,
      start_datetime: time,
      end_datetime: time,
      reoccurring: false,
      type: "Custom",
      location: location,
      participants_required: flatMembers,
    };
      await createEventMeetingByEventUUID({uuid, body})
      setMeeting([...meeting, {name, start_datetime: time.toISOString(), end_datetime: time.toISOString(), location, members}]);
      setLoading(false);
      setMeetingModalOpen(false);
  };

  

  const offerSubmitHandler = async (
    event_role_uuid,
    rate,
    event_uuid,
    user_uuid,
    modalOpen,
    offerType,
    terms
  ) => {
    setLoading(true);
    const URL = `/api/agreements/create-finalize`;

    const body = {
      event_role_uuid: event_role_uuid,
      rate: rate,
      event_uuid: event_uuid,
      user_uuid: user_uuid,
      offerType,
      terms
    };
    try {
      const eventResponse = await axios.post(URL, {
        ...body,
      });
      modalOpen(false);
      setPeople(eventResponse.data.roles);
      if (eventResponse.status === 200) {
        dispatch(
          showSuccess({
            heading: "Success!",
            message: eventResponse.data.message,
            status: eventResponse.data.updated,
          })
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const URL = `/api/event-config-options`;
    axios.get(URL).then((response) => {
      setEventConfigOptionsData(response.data);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (data) {
      setEvent(data);
      setPeople(data.roles);
      setEventConfigData(data.configs);
      setMeeting(data.meetings);
      setMeetingParticipants(data.meeting_participants);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const URL = `/api/event-roles`;
    axios.get(URL).then((response) => {
      setEventRoleData(response.data);
    });
  }, []);

  // async staff setter
  useEffect(() => {
    const URL = `/api/users`;
    axios.get(URL).then((response) => {
      setEventStaffAvailable(response.data);
    });
  }, []);

  useEffect(() => {
    const URL = `/api/events/${uuid}/meta`;
    axios.get(URL).then((response) => {
      setEventMeta(response.data);
    });
  }, []);

  const Communication = () => {
    return (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Send event communication
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>Need to communicate a custom message to the entire team?</p>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              <button
                type="button"
                onClick={() => setCommModalOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gemstoneblue hover:bg-gemstoneroyal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gemstoneblue-500 sm:text-sm"
              >
                Draft Message
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="grid auto-rows-auto gap-y-10">
      <div className="grid grid-cols-2 gap-x-4 mb-6">
        {/* Main 2 column grid */}
        <EventInfo
          event={event}
          user={user}
          loading={loading}
          isEditingDetails={isEditingDetails}
          setEditingDetails={setEditingDetails}
        />

        {/* Right column */}

        {hasRole(user, ['Tenant Admin']) && <section aria-labelledby="section-2-title">
          <h2 className="sr-only" id="section-2-title">
            Event Feed
          </h2>
          <div className="rounded-lg bg-white overflow-hidden shadow">
            <div className="p-6">
              <Feed timeline={eventMeta} loading={loading} />
            </div>
          </div>
        </section>}
      </div>
      <div>
        <section
          aria-labelledby="event-staff"
          className={`mb-6 ${loading && "animate-pulse"}`}
        >
          <CardWithAction
            heading="Event Staff"
            button="Add"
            allowedAction={hasRole(user, ['Tenant Admin'])}
            handler={() => {
              popModalFor("eventStaff");
            }}
            content={
              <AvatarTable
                removeItemFrom={removeItemFrom}
                people={people}
                setter={setPeople}
                event={event}
                offerSubmitHandler={offerSubmitHandler}
                loading={loading}
                cancelOffer={cancelOffer}
                removeItemNotification={removeItemNoticeDispatcher}
              />
            }
          />
          {hasRole(user, ['Tenant Admin']) && <Communication /> }
        </section>

        <section
          aria-labelledby="event-configs"
          className={`mb-6 ${loading && "animate-pulse"}`}
        >
          <CardWithAction
            heading="Event Configuration"
            button="Add"
            allowedAction={hasRole(user, ['Tenant Admin'])}
            handler={() => {
              popModalFor("eventConfiguration");
            }}
            content={
              <EventConfigList
                collection={eventConfigData}
                removeItemFrom={removeItemFrom}
                removeItemNotification={removeItemNoticeDispatcher}
                setter={setEventConfigData}
                event={event}
              />
            }
          />
        </section>

        {/* <section aria-labelledby="event-schedule" className="mb-6">
          <CardWithAction
            heading="Event Schedule"
            button="Add"
            handler={() => {
              popModalFor("eventSchedule");
            }}
            content={<EventMeetingList collection={[]} />}
          />
        </section> */}

        <section
          aria-labelledby="event-meetings"
          className={`mb-6 ${loading && "animate-pulse"}`}
        >
          <CardWithAction
            heading="Event Meetings"
            button="Add"
            allowedAction={hasRole(user, ['Tenant Admin'])}
            handler={() => {
              setMeetingModalOpen(true)
            }}
            content={
              <EventMeetingList
                removeItemFrom={removeItemFrom}
                event={event}
                collection={meeting}
                setter={setMeeting}
                handleRemoveMemberFromMeeting={handleRemoveMemberFromMeeting}
                removeItemNotification={removeItemNoticeDispatcher}
                meetingParticipants={meetingParticipants}
                people={people}
              />
            }
          />
        </section>

        {/* <section aria-labelledby="event-map" className="mb-6">
          <CardWithAction
            heading="Event Map"
            button="Add"
          />
        </section> */}
      </div>
      <Modal
        modalData={modalData}
        collection={EventConfigurations}
        ecOCH={handleSetEventConfigData}
        eventStaffOnClick={handleSetEventStaff}
        assignRole={handleAssignStaffRole}
        type={modalContent}
        open={open}
        setOpen={setOpen}
        people={people}
      />
      <GenericModal
        open={meetingModalOpen}
        setOpen={setMeetingModalOpen}
      >
        <EventMeetingLayout
          collection={eventMeetingData}
          people={people}
          onClickHandler={handleSetEventMeeting}
        />
      </GenericModal>
      <CommunicationModal
        onChange={handleCommunicationChange}
        open={commModalOpen}
        value={commMessage}
        setOpen={setCommModalOpen}
        onSubmit={handleCommunicationSubmit}
      />
      <EditEventPanel
        validateFields={validateFields}
        onSubmitHandler={onSubmitHandler}
        loading={loading}
        setLoading={setLoading}
        history={history}
        axios={axios}
        dispatch={dispatch}
        values={event}
        open={isEditingDetails}
        setOpen={setEditingDetails}
      />
      {event.address && <CardWithAction
        heading="Event Map"
        button="Add"
        handler={() => {
        }}
        content={
          <EventMap address={event.address}/>
        }
      />}
    </div>
  );
}
