import { isOptionalTypeNode } from "typescript";

export default function SimpleDropdown(props) {
    const { options, title, onChange, disabled, data } = props;
    const lowerTitle = title?.toLowerCase();
    return (
      <div>
        <label htmlFor={lowerTitle} className="block text-sm font-medium text-gray-700">
          {title}
        </label>
        <select
          id={lowerTitle}
          disabled={false || disabled}
          name={lowerTitle}
          onChange={(e) => onChange(e, data)}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gemstoneblue-500 focus:border-gemstoneroyal-500 sm:text-sm rounded-md"
          defaultValue={""}
        >
          {
              options && options.map((option) => {
                  return (
                      <option 
                        data-name={option?.role_name}
                        key={option.name || option.event_name || option.uuid || option.id  }
                        value={option.name || option.event_name || option.uuid || option.id }>
                            {option.name || option.role_name || option.event_name}
                        </option>
                  )
              })
          }
        </select>
      </div>
    )
  }
  